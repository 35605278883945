import NAWECService from '@/services/nawec.service';
import ApiService from '@/services/api.service';
import JwtService from '@/services/jwt.service';
// import WebSocketClient from "@/lib/websocket-client";
// import {API_URL} from '@/services/api.service';
// const webSocketClient = new WebSocketClient(API_URL + "/ws");


export default {
  // eslint-disable-next-line no-empty-pattern
 /** aCreateNawecPaymentIntent({ commit }, payload) {
    return new Promise((resolve, reject) => {
      NAWECService.createIntent(payload).then(function(response){
        commit('mSetNawecPaymentIntent', response.data);
        console.log('create payment intent:', response.data)
        resolve(response);
      }).catch(function (err) {
        // alert(err);
        reject(err);
      });
    });
  },*/

  async aCreateNawecPaymentIntent({ dispatch }, data) {
    console.log('create payment intent:', data)
    try {
      const payload = {
        ...data
      };

      const result  = await NAWECService.createNawecPaymentIntent(payload);
      console.log('result...:', result)
      //state.nawecPaymentIntent = data;
      if (result && result.status) {
        const payload = {
          secret: result.data.secret,
          target: data.mobile,
        }
        dispatch("aConfirmNawecPaymentIntent", payload);
      } else {
        // store.commit("mSetNawecPaymentIntent", null);
      }
    } catch (error) {
      console.log(error) 
    }
  },

  aConfirmNawecPaymentIntent({ commit }, payload){
    return new Promise((resolve, reject)=>{
      NAWECService.confirmNawecPaymentIntent(payload).then(function(response){
        commit('mSetConfirmNawecPayment', response.data);
        resolve(resolve);
      }).catch(function (err){
        reject(err);
      })
    })
  },

  aSendNawecToken({ commit }, payload){
    return new Promise((resolve, reject)=>{
      NAWECService.sendSMS(payload).then(function(response){
        commit('mSetConfirmNawecPayment', response.data);
        resolve(resolve);
      }).catch(function (err){
        reject(err);
      })
    })
  },

  async aPurchaseDetail({commit}, payload){
    commit('mBuyCashpower',payload);
    //commit('mSetPurchaseAmount', payload.amountPurchase);
    //commit('mSetMeterNumber', payload.meterNumber);
        // let amountPurchase= state.amountPurchase
        // let meterNumber= state.meterNumber
    console.log("aPurchaseDetail",payload)
  },

  aCheckAuth({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
    } else {
      commit('mPurgeAuth');
    }
  }
}
