export const fromCurrencyCode = 'GBP';
export const toCurrencyCode = 'GMD';

import { TENANT } from "@/config";

// Supporrted payments : https://www.revolut.com/business/help/merchant-accounts/payments/in-which-currencies-can-i-accept-merchant-payments
// Stripe apple pay https://stripe.com/docs/stripe-js/elements/payment-request-button

let currencies = [
    {
        symbol: '£',
        code: 'GBP',
        name: 'British Pounds'
    },
    {
        symbol: '€',
        code: 'EUR',
        name: 'Euro'
    },
    {
        symbol: '$',
        code: 'USD',
        name: 'US Dollar'
    }
];

if(TENANT === 'taybullpay' || TENANT === 'taybullsend') {
    currencies = [
        {
            symbol: 'GMD',
            code: 'GMD',
            name: 'Gambian Dalasis'
        },
        {
            symbol: '£',
            code: 'GBP',
            name: 'British Pounds'
        },
        {
            symbol: '€',
            code: 'EUR',
            name: 'Euro'
        },
        {
            symbol: '$',
            code: 'USD',
            name: 'US Dollar'
        }
    ];
}

const eurCountries = [
    'AL',
    'RS',
    'TR',
    'BA',
    'XK',
    'AM',
    'AZ',
    'BY',
    'MD',
    'GE',
    'UA',
    'RU',
    'BE',
    'BG',
    'CZ',
    'DK',
    'DE',
    'EE',
    'IE',
    'EL',
    'ES',
    'FR',
    'HR',
    'IT',
    'CY',
    'LV',
    'LT',
    'LU',
    'HU',
    'MT',
    'NL',
    'AT',
    'PL',
    'PT',
    'RO',
    'SI',
    'FI',
    'SE',
    'ME',
    'MK',


];

const gbpCountries = [
    'GB',
    'UK',
    // 'GM'
];

const gmdCountries = [
    'GM',
];

export const getCurrency = (countryCode) => {
    if(!countryCode) {
        return 'GBP';
    }
    if(gbpCountries.includes(countryCode)) {
        return 'GBP';
    }
    if(eurCountries.includes(countryCode)) {
        return 'EUR';
    }
    if(gmdCountries.includes(countryCode) && TENANT === 'taybullpay') {
        return 'GMD';
    }
    return 'USD';
}

export {
    currencies
};