// const loadBooleanEnv = (envName) => process.env[envName] === 'true' || process.env[envName] === true;
/* eslint-disable no-undef */
const { version: VERSION } = require("../package.json");

const { 
  API_URL, 
  REVOLUT_ENV, 
  STRIPE_KEY, 
  TENANT, 
  PRODUCT, 
  THEME, 
  VERIFF_API_KEY, 
  TRUSTPAYMENTS_SITEREFERENCE, 
  TRUSTPAYMENTS_DOMAIN, 
  TRUSTPAYMENTS_STSPROFILE, 
  TRUSTPAYMENTS_VERSION,
  TRUSTPAYMENTS_WEBHOOK,
} = process.env;

const env = {
  VERSION,
  API_URL,
  REVOLUT_ENV,
  STRIPE_KEY,
  VERIFF_API_KEY,
  TENANT,
  PRODUCT,
  THEME,
  TRUSTPAYMENTS_SITEREFERENCE: TRUSTPAYMENTS_SITEREFERENCE ? TRUSTPAYMENTS_SITEREFERENCE : 'NONE', 
  TRUSTPAYMENTS_DOMAIN: TRUSTPAYMENTS_DOMAIN ? TRUSTPAYMENTS_DOMAIN : 'NONE', 
  TRUSTPAYMENTS_STSPROFILE: TRUSTPAYMENTS_STSPROFILE ? TRUSTPAYMENTS_STSPROFILE : 'NONE', 
  TRUSTPAYMENTS_VERSION: TRUSTPAYMENTS_VERSION ? TRUSTPAYMENTS_VERSION : 'NONE',
  TRUSTPAYMENTS_WEBHOOK: TRUSTPAYMENTS_WEBHOOK ? TRUSTPAYMENTS_WEBHOOK : 'NONE'
};

module.exports = env;
