import { createSendMoneyParams } from "../../../lib/send-money";
export default {
  // mSetTransactionDetail(state, transactionDetail) {
  //   state.transactionDetail = null;
  //   state.transactionDetail = { ...transactionDetail };
  // }, 
  mSetTransactionDetail() {
  },
  mSendMoney(state, params) {
    const payload = createSendMoneyParams({});
    // console.log("send money");
    state.sendmoneyParams = null;
    state.sendmoneyParams = params ? { ...params } : payload;
    state.params = params ? { ...params } : payload;
    state.sendmoneyDialog = true;
    state.sendmoneyDialogKey = new Date().toLocaleString();
    state.key = state.sendmoneyDialogKey;
  },
  mSendMoneyForUser(state, params) {
    const payload = createSendMoneyParams({});
    // console.log("send money");
    state.sendmoneyParams = null;
    state.sendmoneyParams = params ? { ...params } : payload;
    state.params = params ? { ...params } : payload;
    state.sendmoneyDialog = true;
    state.sendmoneyDialogKey = new Date().toLocaleString();
    state.key = state.sendmoneyDialogKey;
  },
  mSetKey(state) {
    state.key = new Date().toLocaleString();
  },
  mLoading(state, value) {
    state.loading = value;
  },
  mUpdateSendMoneyDialog(state, value) {
    state.sendmoneyDialog = value;
  },
  mResetParams(state) {
    state.sendmoneyParams = null;
    state.params = null;
  },
  mSetParams(state, params) {
    state.params = { ...params };
  },
  mSetConfig(state, config) {
    // console.log('config', config);
    const { charges, exchangeRates } = config;
    state.transferTypes = charges.filter((item) => item.group === 'MONEY-TRANSFER-TYPES');
    state.deliveryTypes = charges.filter((item) => item.group === 'MONEY-TRANSFER-DELIVERY-TYPES');
    state.exchangeRates = exchangeRates;
    state.configSet = true;
  },
};
