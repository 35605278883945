export default {
      common: {
        "title": "A simple example",
        "subtitle": "It's a blank-page. Start to build your'e page from here.",
        "error_with_status": "An error occurred on server",
        "404": "This page could not be found.",
        "404_subtitle": "You can either stay and chill here, or go back to the beginning.",
        "back": "back to home",
        "error_without_status": "An error occurred on the server",
        "contact_title": "Free Project Quote",
        "contact_title2": "Say Hello to Us",
        "contact_subtitle": "Do you have any questions? Are you ready to reduce cost and create new revenue? Or you simply want to say hi? Drop us a message.",
        "form_name": "What is your name? *",
        "form_email": "What it your email? *",
        "form_phone": "What is your phone number?",
        "form_company": "What is your company?",
        "form_message": "Write your message here",
        "form_terms": "I have read and accept the Terms of",
        "form_privacy": "Service & Privacy Policy *",
        "form_send": "Send Message",
        "login": "login",
        "login_create": "Create new account",
        "login_or": "Or login with email",
        "login_email": "Email",
        "login_password": "Password",
        "login_remember": "Remember",
        "login_forgot": "Forgot Password",
        "continue": "Continue",
        "login_title": "Welcome back",
        "login_subtitle": "Please login to continue",
        "register": "Register",
        "register_already": "Already have account?",
        "register_or": "Or register with email",
        "register_name": "Name? *",
        "register_email": "Email? *",
        "register_password": "Password",
        "register_confirm": "Confirm Password",
        "register_title": "Nice to meet you :)",
        "register_subtitle": "Just register to join with us",
        "accept": "Accept",
        "en": "English",
        "de": "Deutsch",
        "zh": "简体中文",
        "pt": "󠁥󠁮󠁧󠁿Português",
        "id": "󠁥󠁮󠁧󠁿Bahasa Indonesia",
        "ar": "󠁥󠁮󠁧󠁿العربيّة",
        "notif_msg": "This is an example of a message that is commonly used for important information to visitors or users. Please click the button beside to close this message."
      },
      mobileLanding: {
        "header_language": "language",
        "header_theme": "Theme mode",
        "header_dark": "dark",
        "header_light": "light",
        "header_feature": "feature",
        "header_showcase": "showcase",
        "header_testimonials": "testimonials",
        "header_faq": "faq",
        "header_login": "Log In",
        "header_signup": "Sign Up",
        "header_sendmoney": "Send Money",
        "header_buycashpower": "Buy Cash Power",
        "header_buyairtime": "Buy Airtime",
        "header_buygiftcard": "Buy Gift Card",
        "header_buyticket": "Buy Ticket",
        "header_locateus": "Pay Out Locations",
        "header_contactus": "Contact Us",
        "header_events": "Events",
        "header_paybills": "Pay Bills",
        "header_topupairtime": "Buy Airtime",
        "banner_title": "Welcome to your mobile",
        "banner_titlestrong": "command center",
        "banner_desc": "It's an all-in-one tool for tracking how well your campaigns are performing.",
        "counter_downloads": "downloads",
        "counter_transaction": "transaction",
        "counter_ratting": "rating",
        "feature_title": "feature",
        "feature_title1": "Boost Mobile App Revenues",
        "feature_desc1": "Increase mobile sales & conversions using fully native mobile apps. Get up to 5X more conversion than the mobile web.",
        "feature_title2": "Create and manage on mobile",
        "feature_desc2": "You can select parameters such as your objective and audience. You can also manage creative work, write, copy and review on different devices.",
        "feature_title3": "See how it works",
        "feature_desc3": "We offers solutions for you to get work easily, however you do business.",
        "feature_watch": "Watch Video",
        "feature_video": "For Mobile Apps - automate mobile ads",
        "showcase_title": "It's the tools you need",
        "faq_subtitle": "Have a question? Check out our frequently asked questions to find your answer.",
        "testimonial_title": "testimonials",
        "company_title": "Trusted by organizations worldwide",
        "news_title": "Latest News",
        "news_desc": "Read more about latest news and our special event",
        "footer_text": "What are you waiting for? Download Now!"
      },
      hostingLanding: {
        "header_login": "log in",
        "header_register": "register",
        "header_language": "language",
        "header_theme": "Theme mode",
        "header_dark": "dark",
        "header_light": "light",
        "header_promotion": "promotion",
        "header_feature": "feature",
        "header_benefit": "benefit",
        "header_pricing": "pricing",
        "header_testimonials": "testimonials",
        "header_contact": "contact",
        "banner_title": "The best web hosting service for your website.",
        "banner_subtitle": "Web hosting made easy & affordable! FREE Get Your Domain On! 1 Year Domain Registration on Shared Annual Plans",
        "banner_label": "enter your domain name",
        "banner_button": "search",
        "promo_periode": "periode",
        "mainfeature_headtitle": "feature",
        "mainfeature_title": "All you need to grow online.",
        "mainfeature_titleitem1": "Send money to your family and friends stress-free",
        "mainfeature_descitem1": "Send money, buy Cash Power & Airtime top-ups & much more for your family and friends or even a business in The Gambia quickly, securely and cost-effectively.",
        "mainfeature_titleitem2": "For smarter money transfers, TaybullPay leads the way",
        "mainfeature_descitem2": "Our easy to use platform is designed for fast and smooth transfers and provides you with real-time visibility of your payments for better cash flow management..",
        "mainfeature_titleitem3": "Peace of mind comes as standard",
        "mainfeature_descitem3": "TaybullPay is registered with, and regulated by, the Financial Conduct Authority (FCA) and operates under HMRC’s Anti-Money Laundering regulation.",
        "learnmore": "learn more",
        "morefeature_headtitle1": "EASY INTEGRATIONS",
        "morefeature_title1": "Fast, secure and always online.",
        "morefeature_desc1": "With industry-leading load times, guaranteed 99.9% uptime and expert, 24/7 support, your site couldn’t ask for more.",
        "morefeature_headtitle2": "SMART DEPLOYMENT",
        "morefeature_title2": "Deploy and scale seamlessly",
        "morefeature_desc2": "All transactions are processed using secure SSL payment technology and we never store your payment information. Furthermore we use Multi-factor authentication to provide an extra layer of protection.",
        "morefeature_headtitle3": "REALTIME MONITORING",
        "morefeature_title3": "Resource metrics and Real-time alerts",
        "morefeature_desc3": "Negate the need to make in person payments which can be laborious, time consuming and potentially costly by performing transactions from the comfort and convenience of your own home or workplace to ensure your payments arrive super-fast. These can be carried out in the App on your mobile, tablet or desktop.",
        "benefit_headtitle": "benefit",
        "benefit_title": "Trust your cloud",
        "getstarted": "get started",
        "pricing_headtitle": "PRICING & PLAN",
        "pricing_title": "Simple, predictable pricing.",
        "getstartednow": "get started now",
        "testi_headtitle": "OUR CUSTOMERS SAID",
        "testi_title": "They are doing great things with us",
        "footer_counter": "Users and Counting",
        "footer_subscribe": "Get our newsletter, join the community:",
        "footer_mail": "Your email",
        "footer_link": "Quick Links",
        "footer_news": "News",
        "subscribe": "subscribe"
      },
      feature: {
        "title": "Stress-Free With TaybullPay!",
        "caption": "Money Transfer To The Gambia",
      },
}
