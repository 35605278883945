import ApiService from "./api.service";
import PublicApiService from "./public-api.service";

const UsersService = {
  getServerConfig() {
    return PublicApiService.get("/p/pay/config/nawec");
  },
  getWallet(code) {
    return ApiService.query("/wallet/" + code);
  },
  getWallets() {
    // console.log('List of Wallets')
    return ApiService.query("/wallet/list");
  },
  lockAccount(payload) {
    // console.log("here payload");
    return ApiService.post("/wallet/lock", payload);
  },
  unlockAccount(payload) {
    // console.log("here payload");
    return ApiService.post("/wallet/unlock", payload);
  },
  getWalletsUsingCode(code) {
    // console.log('List of Wallets')
    return ApiService.query("/wallet/list/" + code);
  },
  getSubAgents(){
    console.log("List of sub agents")
    return ApiService.query("/wallet/agents")
  },
  signUp(payload) {
    // console.log("here payload");
    return ApiService.post("/user/pay/register", payload);
  },
  update(payload) {
    
    return ApiService.post("/user/detail", payload);
  },
  webSignUp(payload) {
    // console.log("here payload");
    return ApiService.post("/user/taybullpay/web/register", payload);
  },
  webSendVerificationCode(payload) {
    // console.log("here payload");
    return ApiService.post("/user/taybullpay/web/send-verification-code", payload);
  },
  webSendEmailVerificationCode(payload) {
    // console.log("here payload");
    return ApiService.post("/user/taybullpay/web/send-email-verification-code", payload);
  },
  webVerifyCode(payload) {
    // console.log("here payload");
    return ApiService.post("/user/taybullpay/web/verify-code", payload);
  },
  webVerifyEmailCode(payload) {
    // console.log("here payload");
    return ApiService.post("/user/taybullpay/web/verify-email-code", payload);
  },

  signIn(payload) {
    return ApiService.post("/api/signin", payload);
  },
  signInUsingToken() {
    return ApiService.post("/user/transaction/token", {});
  },
  changePassword(payload) {
    return ApiService.post("/user/change-password", payload);
  },
  trackEvent(payload) {
    return ApiService.post("/user/track", payload);
  },
  verifyEmail(payload) {
    return ApiService.post("/api/verify-email", payload);
  },
  status() {
    return ApiService.query("/user/status");
  },
  updateUserDetails(payload) {
    return ApiService.post("/user/detail", payload);
  },
  updateUserAddress(payload){
    return ApiService.post("/user/address", payload)
  },
  changeUserPassword(payload){
    return ApiService.post("/user/taybullpay/change-password", payload)
  },
  requestVerification(payload){
    return ApiService.post("/user/taybullpay/request-verification", payload)
  },
  updateRiskLevel(payload){
    return ApiService.post("/user/risk-level", payload)
  },

 /*  updateUserAddress(payload) {
    return ApiService.post("/user/address", payload);
  }, */
  sendResetPasswordRequest(payload) {
    return ApiService.post("/user/send-reset-password-request", payload);
  },
  resetPassword(payload) {
    return ApiService.post("/user/reset-password", payload);
  },
  getDetail(code) {
    return ApiService.query("/user/detail/" + code);
  },
  createMyVerification(){
    return ApiService.post("/user/identity-verification", {})
  },
  createKey(payload){
    return ApiService.post("/user/key", payload);
  },
  deleteKey(code){
    return ApiService.delete(`/user/key/${code}`);
  },
};

export default UsersService;
