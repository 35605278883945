
const ID_TOKEN_KEY = "id_token";
const ID_USER_KEY = "id_user";
const ID_WALLET = "id_wallet";
const ID_WALLETS = "id_wallets";
const ID_SERVER_CONFIG = "id_server_config";

export const getToken = () => {
  try {
    return window.localStorage.getItem(ID_TOKEN_KEY);
  } catch (error) {
    return null;
  }

};

export const saveToken = (token) => {
  try {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
  } catch (error) {
    alert(error);
    return null;
  }
};

export const destroyToken = () => {
  try {
    window.localStorage.removeItem(ID_TOKEN_KEY);
  } catch (error) {
    return null;
  }
};


export const getUser = () => {
  try {
    return JSON.parse(window.localStorage.getItem(ID_USER_KEY));
  } catch (error) {
    return null;
  }

};

export const saveUser = user => {
  try {
    window.localStorage.setItem(ID_USER_KEY, JSON.stringify(user));
  } catch (error) {
    return null;
  }
};

export const destroyUser = () => {
  try {
    window.localStorage.removeItem(ID_USER_KEY);
  } catch (error) {
    return null;
  }
};
export const getWallet = () => {
  try {
      return JSON.parse(window.localStorage.getItem(ID_WALLET));
  } catch (error) {
      return null;
  }
};

export const getWallets = () => {
  try {
      return JSON.parse(window.localStorage.getItem(ID_WALLETS));
  } catch (error) {
      return [];
  }
};
export const saveWallet = wallet => {
  window.localStorage.setItem(ID_WALLET, JSON.stringify(wallet));
};

export const saveWallets = wallets => {
  window.localStorage.setItem(ID_WALLETS, JSON.stringify(wallets));
};
export const saveServerConfig= config => {
  window.localStorage.setItem(ID_SERVER_CONFIG, JSON.stringify(config));
};
export const getServerConfig = () => {
  console.log('charges')
  try {
      return JSON.parse(window.localStorage.getItem(ID_SERVER_CONFIG));
  } catch (error) {
      return null;
  }
};
export const getObject = (key) => {
  try {
      return JSON.parse(window.localStorage.getItem(key));
  } catch (error) {
      return null;
  }

}

export default { getToken, saveToken, destroyToken, getUser, saveUser, destroyUser, getWallet, getWallets, getServerConfig, saveServerConfig, saveWallet, saveWallets };
