import { parsePhoneNumber } from "libphonenumber-js";
const getTemplate = (state, text) => {
    try {
      const phoneNumber = state
        ? parsePhoneNumber(text, state)
        : parsePhoneNumber(text);
      if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber;
      }
    } catch (error) {
      // console.log("error", error);
    }
    return null;
  };
const getCountryState = (states, text) => {
    let template;
    for (let i = 0; i < states.length; i++) {
      template = getTemplate(states[i], text);
      if (template) {
        break;
      }
    }
    return template;
  };
  const validatePhoneNumber = (number) => {
    const mobileNumber = number;
    let validPhoneNumber = null;
    if (mobileNumber && mobileNumber.trim()) {
      const phoneNumber = getCountryState(
        ["GM", "GB", "US", null],
        mobileNumber
      );
      if (phoneNumber && phoneNumber.isValid()) {
        validPhoneNumber =  phoneNumber.number;
      } else {
        validPhoneNumber = null;
      }
    }
    //  console.log('valid', validPhoneNumber)
    return validPhoneNumber;
  };
  const getAirtimeNumberType = (number) => {
    // console.log('num1', number)
    // if(number.substring(0, 4) === '+220'){
    //   number = number.substring(4);
    // }
    // console.log('num2', number)
    if (
      (number.substring(4))[0] === "7" ||
      (number.substring(4))[0] === "2"
    ) {
      return "Africell";
    } else if (
      (number.substring(4))[0] === "3" ||
      (number.substring(4))[0] === "5"
    ) {
      return "Qcell";
    } else if ((number.substring(4))[0] === "9") {
      return "Gamcell";
    } else if ((number.substring(4))[0] === "6") {
      return "Comium";
    }
  };
  const serviceFeeCalculator = (value, charges) => {
    const charge = charges.find(
      (charge) => value < charge.end && value >= charge.start
    );
    if (charge && charge.type && charge.type === "percentage") {
      // const serviceFee = charge

      const serviceFee = value * (charge.value / 100);
      return serviceFee;
    } else if (charge) {
      const serviceFee = charge.value;
      return serviceFee;
    }
  };
  const getIntent = () => {

  }
  export { getCountryState, validatePhoneNumber, getAirtimeNumberType, serviceFeeCalculator, getIntent}