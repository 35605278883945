import ApiService from "./api.service";

const NAWECService = {
  createIntent(payload) {
    return ApiService.post("/service/nawec-test/create-payment-intent", payload);
  },
  confirm(payload) {
    return ApiService.post("/service/nawec-test/confirm", payload);
  },
  getIntent(secret) {
    return ApiService.query(`/service/nawec-test/${secret}`);
  },
  sendSMS(payload) {
    return ApiService.post('/service/nawec-test/action', payload);
  },
  createNawecPaymentIntent(payload) {
    // console.log('create nawec payment intent', payload)
    return ApiService.post("/service/electricity-purchase/create-payment-intent", payload);
  },
  confirmNawecPaymentIntent(payload) {
    // console.log('confirm nawec payment intent', payload)
    return ApiService.post("/service/electricity-purchase/confirm", payload);
  },
  sendNawecToken(payload) {
    // console.log('send nawec token', payload)
    return ApiService.post("/service/electricity-purchase/action", payload);
  },
  getNawecIntent(secret) {
    // console.log('print nawec receipt', secret)
    return ApiService.query("/service/electricity-purchase/" + secret);
  },
}



export default NAWECService;
