import charges from "./config/nawec-charges";
import numeral from "./numeral";

const chargeCalculator = (value) => {
    //console.log('chargre calculator', value)
    const amountPerKwh = 10.14;
    const charge = charges.find((charge) => value < charge.end && value >= charge.start);
    //console.log(charge)
    if (charge && charge.type && charge.type === 'percentage') {
        // const serviceFee = charge

        const serviceFee = numeral(value).multiply(charge.value / 100).value();
        const paidAmount = value - serviceFee
        const units = paidAmount / amountPerKwh;
        // console.log('calculator', serviceFee)
        const item = {
            originalAmount: numeral(value).value(),
            serviceFee: serviceFee,
            paidAmount: numeral(paidAmount).value(),
            units: numeral(units).format('0.0'),
        }
        return item;
    } else if (charge) {
        const serviceFee = numeral(charge.value).value();
        const paidAmount = value - serviceFee
        const units = paidAmount / amountPerKwh;
        const item = {
            originalAmount: numeral(value).value(),
            serviceFee: serviceFee,
            paidAmount: numeral(paidAmount).value(),
            units: numeral(units).format('0.0'),
        }
        return item;
    }
    return 0;
}

export default {
    chargeCalculator
}
