import Factory from "@/services/factory";
export default {
  async aCreateNawecPaymentIntent({ commit, rootState }, data) {
    let result = null;
    commit("mCreatingIntent", true, {
      root: true,
    });
    try {
      const payload = {
        ...data,
      };

      payload.fromWalletCode = rootState.users.wallet.code;
      payload.public = rootState.users.wallet.public ? true : false;
      payload.userCode = rootState.users.wallet.userCode;
      payload.publisher = rootState.publisher;
      
      const response = await Factory.get("NawecService").createNawecPaymentIntent(payload);
      if (response && response.data) {
        result = response.data;
        commit("basket/mClearIntents", {}, {
          root: true,
        });
        commit("mSetTransactionDetail", response.data);
        commit("basket/mAddIntent", response.data, {
          root: true,
        });
        // dispatch("confirmAirtimePaymentIntent", payload);
      } else {
        commit("mShowAlert", "Error => Transaction failed ", {
          root: true,
        });
      }
    } catch (error) {
      let errorMessage = error.response.data.message;
      commit("mShowAlert", "Error => Transaction failed:  " + errorMessage, {
        root: true,
      });
    }
    commit("mCreatingIntent", false, {
      root: true,
    });
    return result;
  },
  async aConfirmNawecPaymentIntent({ commit, dispatch, rootState }, data) {
    commit("mConfirmingIntent", true, {
      root: true,
    });
    try {
      const payload = {
        ...data,
      };
      if(!payload.fromWalletCode) {
        payload.fromWalletCode = rootState.users.wallet.code;
      }
      
      const result = await Factory.get("NawecService").confirmNawecPaymentIntent(payload);
      if (result && result.status) {
        commit("mShowAlert", "Transaction successfull ", {
          root: true,
        });
        const payload2 = {
          secret: result.data.secret,
          target: payload.mobileNumber,
        };
        dispatch("aSendNawecToken", payload2);
        return result;
      } else {
        commit("mShowAlert", "Error => Transaction Failed ", {
          root: true,
        });
      }
    } catch (error) {
      commit("mShowAlert", "Error => Transaction Failed " + error.message, {
        root: true,
      });
    }
    commit("mConfirmingIntent", false, {
      root: true,
    });
  },
  async aGetPaymentIntentUsingSecret({ commit }, secret) {
    console.log("getIntent");
    try {
      const result = await Factory.get("NawecService").getIntent(secret);
      if (result.data) {
        // store.commit("mUpdateNawecToken", result.data);
        commit("mUpdateNawecToken", result.data);
      } else {
        console.log("data unavailiable");
        commit("mUpdateNawecToken", null);
        commit("mShowAlert", "Internet connectivity issue. Cant load meter number", {
          root: true,
        });
      }
    } catch (error) {
      commit("mUpdateNawecToken", null);
      commit("mShowAlert", "Internet connectivity issue. Cant load meter number", {
        root: true,
      });
    }
  },
  async aSendNawecToken({ commit }, payload) {
    // console.log(payload)
    try {
      const result = await Factory.get("NawecService").sendNawecToken(payload);
      console.log("result", result);
      commit("mShowAlert", "Transaction successful", {
        root: true,
      });
      // if (result) {
      //   store.commit("SET_NAWEC_PAYMENT_INTENT", result);
      // }
    } catch (error) {
      // commit("mShowAlert", "Internet connectivity issue. Cant load meter number", {
      //   root: true,
      // });
      // console.log("error4", error);
    }
  },
};
