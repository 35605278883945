
import JwtService from "@/services/jwt.service";

export default {
  sendmoneyDialog: false,
  sendmoneyDialogKey: null,
  params: null,
  sendmoneyParams: null,
  // sendmoneyPaymentIntent: null,
  serverConfig: JwtService.getServerConfig() ? JwtService.getServerConfig() : null,
  recieverNumber: null,
  loading: false,
  resend: false,
  transactionDetail: null,
  transferTypes: [],
  deliveryTypes: [],
  exchangeRates: [],
  key: new Date().toLocaleString(),
  configSet: false,
}
