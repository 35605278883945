import currency from 'currency.js';

export const calculateAmount = ({ exchangeRate = null, serviceCode, fromCurrencyCode, toCurrencyCode, toAmount = null, fromAmount = null, exchangeRates = [] } = {}) => {
    // console.log("mSetBundles serviceCode", serviceCode);
    // console.log("mSetBundles serviceCode", toCurrencyCode);
   const eR = exchangeRate ? { ...exchangeRate } : exchangeRates.find((item) => serviceCode === item.serviceCode && fromCurrencyCode === item.fromCurrencyCode && toCurrencyCode === item.toCurrencyCode);
//    console.log("mSetBundles eR", eR);
   if(toAmount) {
    return currency(toAmount).divide(eR.rate).value;
   } else {
    return currency(fromAmount).multiply(eR.rate).value
   }
};

export const getExchangeRate = ({ exchangeRate = null, serviceCode, fromCurrencyCode, toCurrencyCode,  exchangeRates = [] } = {}) => {
    const eR = exchangeRate ? { ...exchangeRate } : exchangeRates.find((item) => serviceCode === item.serviceCode && fromCurrencyCode === item.fromCurrencyCode && toCurrencyCode === item.toCurrencyCode);
    return eR;
}



export const createBundles = (serviceCode, fromCurrencyCode, toCurrencyCode, amounts, exchangeRates) => {
    if(fromCurrencyCode === 'GMD') {
        return [];
    }
    const exchangeRate = exchangeRates.find((item) => serviceCode === item.serviceCode && fromCurrencyCode === item.fromCurrencyCode && toCurrencyCode === item.toCurrencyCode);
    const bundles = amounts.map((toAmount) => {
        const row = { ...exchangeRate };
        row.toAmount = toAmount;
        row.fromAmount = calculateAmount({ exchangeRate, serviceCode, fromCurrencyCode, toCurrencyCode, toAmount, exchangeRates});
        return row;
    });
    console.log('bundles', bundles);
    return bundles;
}