import numeral from './numeral';
import Customer from './customer';
export default class Item {
    constructor({ id = null, code = null, image = null, description = null, name = null, summary = null, amount = null, quantity = 1, customer = {}, } = {}) {
        this.id = id;
        this.code = code;
        this.name = name;
        this.image = image;
        this.description = description;
        this.amount = amount;
        this.quantity = quantity;
        this.summary = summary;
        this.customer = new Customer(customer ? customer : {});
        
    }
    setCustomer(customer) {
        this.customer = customer;
    }
    getCustomer() {
        return this.customer;
    }
    getTotal() {
        // alert(this.amount);
        // this.amount = this.amount ? numeral(this.amount).value() : this.amount;
        const amount = this.amount ? this.amount : 0;
        return numeral(amount).multiply(numeral(this.quantity).value()).value();
    }
    getFormatedTotal() {
        return numeral(this.getTotal()).format('$ 0,0.00');
    }
}