import ReportService from "../../../services/report.service";
import IdentityService from "../../../services/public-identity.service";
import { IdentityUser } from "./model/user";

export default {
  async aInit({ commit }, { code }) {
    commit('mSetLoading', true);
    try {
      const response = await IdentityService.getUser(code);
      const data = response.data;
      const user = new IdentityUser();
      user.loadFromObject(data);
      commit('mSetUser', user);


    } catch(error) {
      //
    }
    commit('mSetLoading', false);
  },
  async aSaveUserAndDocuments({ state, commit }) {
    commit('mSetSaving', true);
    try {

      const requests = [IdentityService.saveUser(state.user.toObject())];
      state.documents.forEach((document) => {
        requests.push(IdentityService.saveDocument(document.toObject()));
      })

      const response = await Promise.all(requests);
      console.log("response.data", response);
    } catch(error) {
      //
    }
    commit('mSetSaving', false);
  },
  async getReport({ state, commit }, { report, view, params }) {
    if(!state[report]) {
        return;
    }
    state[report].loading = true;
    try {
        const r = state[report];
        state[report].loading = true;
        const response = await ReportService.getReport(view ? view : r.name, params);
        commit('mSetReportResult', { report, result: response.data.items })
    } catch (error) {
      //
      
    }
    state[report].loading = false;
  },
};
