import css from '!raw-loader!./config/print.css';
import numeral from './numeral';

const printReceipt = (order) => {
    const message = JSON.parse(order.apiMessage);
    const stsPaymentResult = message.stsPaymentResults[0];
    const request = message.request;
    // console.log('message', message);
    // console.log('order', order);
    const windowUrl = 'about:blank';
    const uniqueName = new Date();
    const windowName = 'Print' + uniqueName.getTime();

    const printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=800,height=600');

    printWindow.document.write('<html><head><title></title>');
    printWindow.document.write(`<style type="text/css">${css}</style>`);


    
    printWindow.document.write('</head><body><div class="print-area">');
    let innerHtml = '';
    innerHtml += '<div class="center"><img src="./assets/chapman-grey.png" alt="chapman logo" /></div>';
    innerHtml += '<table width="100%">';
    innerHtml += '<tr>';
    innerHtml += '<td class="left">Customer</td>';
    innerHtml += `<td class="right">${stsPaymentResult.customerName}</td>`;
    innerHtml += '</tr>';

    innerHtml += '<tr>';
    innerHtml += '<td class="left">Receipt No</td>';
    innerHtml += `<td class="right">${stsPaymentResult.receipt}</td>`;
    innerHtml += '</tr>';

    innerHtml += '<tr>';
    innerHtml += '<td class="left">Meter No</td>';
    innerHtml += `<td class="right">${stsPaymentResult.meterSerial}</td>`;
    innerHtml += '</tr>';

    innerHtml += '<tr><td colspan="2" class="center">*******************************</td></tr>';
    innerHtml += '<tr>';
    innerHtml += '<td class="left">Token</td>';
    innerHtml += `<td class="right">${stsPaymentResult.listToken[0]}</td>`;
    innerHtml += '</tr>';
    innerHtml += '<tr><td colspan="2" class="center">*******************************</td></tr>';

    innerHtml += '<tr>';
    innerHtml += '<td class="left">Amount Tendered</td>';
    innerHtml += `<td class="right">${numeral(request.amount).format('$0,0.00')}</td>`;
    innerHtml += '</tr>';

    innerHtml += '<tr>';
    innerHtml += '<td class="left">Service Fee</td>';
    innerHtml += `<td class="right">${numeral(request.serviceFee).format('$0,0.00')}</td>`;
    innerHtml += '</tr>';

    innerHtml += '<tr>';
    innerHtml += '<td class="left">Units</td>';
    innerHtml += `<td class="right">kWh ${stsPaymentResult.units}</td>`;
    innerHtml += '</tr>';

    innerHtml += '<tr><td colspan="2" class="center">*******************************</td></tr>';

    innerHtml += '<tr>';
    innerHtml += '<td class="left">Operator</td>';
    innerHtml += `<td class="right">Pa Essa Jabang</td>`;
    innerHtml += '</tr>';
    innerHtml += '<tr>';
    innerHtml += '<td class="left">Station</td>';
    innerHtml += `<td class="right">Serrekunda</td>`;
    innerHtml += '</tr>';
    innerHtml += '<tr><td colspan="2" class="center" style="padding-top: 20px;">-------------Advertisement------------</td></tr>';
    innerHtml += '<tr><td colspan="2" class="center" style="padding-top: 5px;">Diamond Platnumx Live</td></tr>';
    innerHtml += '<tr><td colspan="2" class="center" style="padding-top: 5px;">Live @ Independece Stadium</td></tr>';
    innerHtml += '<tr><td colspan="2" class="center" style="padding-top: 5px;">18th December 2021</td></tr>';
    innerHtml += '<tr><td colspan="2" class="center" style="padding-top: 10px;">-------------Advertisement------------</td></tr>';

    innerHtml += '</table>';
    

    
    // assets/chapman-grey.png
    printWindow.document.write(innerHtml);
    printWindow.document.write('</div></body></html>');

    printWindow.document.close(); // necessary for IE >= 10
    printWindow.focus(); // necessary for IE >= 10*/

    printWindow.print();
    const timeout = setTimeout(() => {
        printWindow.close();
        clearTimeout(timeout);
    }, 1000);
}

const printReceipt2 = () => {
    const printContent = document.getElementById('print-area');
    const windowUrl = 'about:blank';
    const uniqueName = new Date();
    const windowName = 'Print' + uniqueName.getTime();

    const printWindow = window.open(windowUrl, windowName, 'left=50000,top=50000,width=800,height=600');
    // let innerHTML = '';
    // innerHTML +=   '<h1>Hello World</h1>');
    document.body.innerHTML = printContent.innerHTML;
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    const timeout = setTimeout(() => {
        printWindow.close();
        clearTimeout(timeout);
    }, 1000);
    return false;
}

export { printReceipt, printReceipt2 };