import ApiService from "./api.service";

const AirtimeService = {
  createAirtimePaymentIntent(payload) {
    console.log('create airtime payment intent', payload)
    return ApiService.post("/service/airtime/create-payment-intent", payload);
  },
  confirmAirtimePaymentIntent(payload) {
    console.log('confirm airtime payment intent', payload)
    return ApiService.post("/service/airtime/confirm", payload);
  },

  requeueAirtimeRequest(id){
    console.log("requeue request with id", id)
    return ApiService.post("/service/airtime/sim-queue/" + id);
  }
  
}


export default AirtimeService;