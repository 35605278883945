const options = {
  "NAWEC-PREPAY": {
    icon: "0xf0e7",
    class: "nawec_prepay",
    chartLabel: "Cash Power",
    color: "#FFA833",
  },
  "MONEY-TRANSFER": {
    icon: "0xf129",
    class: "money_transfer",
    chartLabel: "Transfer",
    color: "#EF5350",
  },
  "WALLET-TOPUP": {
    icon: "0xf067",
    class: "wallet_topup",
    chartLabel: "Wallet Top Up",
    color: "#42A5F5",
  },
  "PAY-OUT": {
    icon: "0xf101",
    class: "pay_out",
    chartLabel: "Pay Out",
    color: "#88C8C2",
  },
  "PAY-IN": {
    icon: "0xf100",
    class: "pay_in",
    chartLabel: "Pay In",
    color: "#8CDD98",
  },
  DEFAULT: {
    icon: "0xf02b",
    class: "default_transaction",
    chartLabel: "Transaction",
    color: "#EC407A",
  },
  REWARD: {
    icon: "0xf02b",
    class: "default_transaction",
    chartLabel: "Cashback",
    color: "#355BD6",
  },
  SALARY: {
    icon: "0xf02b",
    class: "default_transaction",
    chartLabel: "Salary Payment",
    color: "#355BD6",
  },
  COMMISSION: {
    icon: "0xf02b",
    class: "default_transaction",
    chartLabel: "Commission",
    color: "#355BD6",
  },
};
// const formatter = new Intl.NumberFormat('en-GB', {
//   style: 'currency',
//   currency: 'GMD',
// }, '#,##0.00');
// const dateFormat = new Intl.DateTimeFormat(null, null, 'd MMMM');
export default {
  mSetPayoutsList(state, data) {
    state.payouts = null;
    let list = [];
    if (data) {
      list = data.item;
      return;
    }
    state.payouts = list;
  },
  mSetPendingTransactions(state, data){
    state.pendingTransactions= null;
    let list = []
    if(!data){

      return
    }
    list= data
    console.log("testtt")
    state.pendingTransactions= list
    console.log("pending trans...", state.pendingTransactions)
  },
  mSetTransactionList(state, data) {
    
    state.transactions = null;
    let list = [];
    if (data) {
      list = data
        .filter((item) => item.name && (item.meterNo || item.mobile))
        .map((item) => {
          if (item.meterNo) {
            const index = item.name + " " + item.meterNo;
            item.index = index.toLowerCase();
          } else if (item.mobile) {
            const index = item.name + " " + item.mobile;
            item.index = index.toLowerCase();
          }
          return item;
        });
    }
    state.transactions = list;
  },

  mUpdateTransactions(state, data) {
    if (!data) {
      return;
    }
    console.log("data", data);
    const transactions = data
      .filter((item) => item.transaction_type !== "COMMISSION" && item.transaction_type !== "AIR-COMMISSION" && item.transaction_type !== "AIR-REWARD" && item.description !== "Reward")
      .map((item) => {
        const option = options[item.transaction_type] ? options[item.transaction_type] : options["DEFAULT"];
        const result =  {
          icon: String.fromCharCode(option.icon),
          class: option.class,
          amount: item.amount,
          // absAmount: Math.abs(item.amount),
          // pretty_amount: formatter.format(item.amount),
          status: "completed",
          description: item.description ? item.description : "",
          category: item.transaction_type,
          name: state.transactionType === "CASHPOWER" ? item.description : item.transaction_type,
          itemType: item.transaction_type,
          
          
          //date: new Date(item.dt).toLocaleDateString(),
          date:item.pretty_dt,
          // pretty_date: dateFormat.format(new Date(item.dt)),
          chartLabel: option.chartLabel,
          color: option.color,
          secret: item.secret,
          id: item.id,
          mobile: item.mobile,
        };
        return { ...item, ...result };
      });
    // console.log('transactionssss', transactions)
    state.transactions = transactions;
  },
  mSetTransactionType(state, data) {
    state.transactionType = null;
    state.transactionType = data;
  },
  mLoadTransactionsDialog(state, data) {
    state.transactionsDialog = true;
    state.transactionsParam = data;
    state.transactionsDialogKey = new Date().toLocaleString();
  },
  mUpdateTransactionDialog(state, value) {
    state.transactionsDialog = value;
  },
};
