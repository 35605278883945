import PublicCommerceService from "../../../services/public-commerce.service";
import CommerceService from "../../../services/commerce.service";

export default {
  async aGetItems({ commit, state }, input) {
    commit("mSetLoading", true);
    try {
      const params = input ? { ...input } : {};
      params.currency = state.currency;
      // console.log("parammsss", params)
      commit("mSetFilteredParams", input);
      const response = await PublicCommerceService.getItems(params);
      const data = response.data;

      commit("mSetItemResponse", data);
    } catch (error) {
      //
    }
    commit("mSetLoading", false);
  },
  async aInitOrder({ commit }, orderCode) {
    try {
      const response = await PublicCommerceService.getOrder(orderCode);
      const data = response.data;
      commit("mSetOrder", data);
    } catch (error) {
      //
    }
  },
  async aGetCategories({ commit }) {
    commit("mSetLoading", true);
    try {
      const response = await PublicCommerceService.getCategories();
      const data = response.data.items;
      console.log("Categories", data);
      commit("mSetCategory", data);
    } catch (error) {
      //
    }

    commit("mSetLoading", false);
  },
  async aGetActiveTickets({ commit, state }, params) {
    commit("mSetLoading", true);
    try {
      // params.status
      const response = await CommerceService.getActiveTickets(params);
      const data = response.data.items;
      state.activeTickets = data;
      console.log("active tickets", data);
    } catch (error) {
      //
    }
    commit("mSetLoading", false);
  },
  async aGetScannedTickets({ commit, state }, params) {
    commit("mSetLoading", true);
    try {
      const response = await CommerceService.getScannedTickets(params);
      const data = response.data.items;
      state.scannedTickets = data;
      console.log("scanned tickets", data);
    } catch (error) {
      //
    }
    commit("mSetLoading", false);
  },
  async aGetMyTickets({ commit, state }) {
    commit("mSetLoading", true);
    try {
      const response = await CommerceService.getMyTickets();
      const data = response.data.items
        state.myTickets = data;
      
      
      console.log("my active tickets",data);
    } catch (error) {
      //
    }
    commit("mSetLoading", false);
  },
  async aMyTickets({commit}){
    commit("mSetLoading", true)
    try{
      const response = await CommerceService.getMyTickets();
      const data = response.data.items
      console.log("tickets", data)
    }catch(error){
      //
    }
    commit("mSetLoading", false)
  },

  

  async aAddItem({ commit, state }, sku) {
    commit("mSetLoading", true);
    try {
      const order = state.order ? state.order : null;

      const payload = {
        orderCode: order && order.code ? order.code : null,
        sku: {
          code: sku.sku_code ? sku.sku_code : sku.code,
        },
        quantity: sku.quantity ? sku.quantity : 0,
      };

      const response = await PublicCommerceService.addItem(payload);
      const data = response.data;
      // console.log("order data", data)
      // console.log("aAddItem", data);
      commit("mSetOrder", data);
      commit("mUpdateItems", null);
    } catch (error) {
      //
    }
    commit("mSetLoading", false);
  },
  async aChangeCurrency({ commit, state, dispatch }, currencyCode) {
    commit("mSetLoading", true);
    try {
      commit("mSetCurrencyCode", currencyCode);
      commit("mSetDefaultCurrencyCode", currencyCode);

      const order = state.order ? state.order : null;

      console.log("order", order);

      const payload = {
        orderCode: order.code,
        currencyCode,
      };

      // console.log("parammsss", params)
      const response = await PublicCommerceService.changeCurrency(payload);

      const data = response.data;

      commit("mSetOrder", data);
      commit("mUpdateItems", null);
    } catch (error) {
      //
    }
    dispatch("aGetItems", state.filteredParams);
    commit("mSetLoading", false);
  },
  async aCreatePaymentIntent({ commit }, params) {
    commit("mSetLoading", true);

    let data = null;
    try {
      const response = await CommerceService.createPaymentIntent(params);
      data = response.data;
      commit("mSetIntent", data);
    } catch (error) {
      //
    }
    commit("mSetLoading", false);
    return data;
  },

  /*  async aGetCurrency({commit}){
    commit('mSetLoading', true);

  } */
};
