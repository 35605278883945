
const initialState = {
  paymentIntent: null,
  paymentTransactions: [],
  paymentParams: null,
  customer: null,
  paymentDialog: false,
  paymentID: null,
  topUpDialog: false,
  fromCurrencyCode: 'GBP',
  toCurrencyCode: 'GMD',
  paymentMethod: 'card',
  cards: null,
  topUpKey: null,
}; 

export default {
  ...initialState
}
