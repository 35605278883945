import PublicApiService from "./public-api.service";

const LocationService = {
  getLocations() {
    return PublicApiService.query('/p/pay/tenant/locations');
  },
  getPayOutAPIs() {
    return PublicApiService.query('/p/pay/tenant/apis');
  },
}

export default LocationService;