import JwtService from '@/services/jwt.service';

const user = JwtService.getUser();
// console.log("user", user);

const initialState = {
  isAuthenticated: user ? true : false,
  nawecPaymentIntent: null,
  loading: false,
  sendNawecToken: null,
  meterNumber: null,
  amountPurchase: null,
  recipientNumber: null,
  senderEmail: null,
  serviceFee: null,
  serverConfig: JwtService.getServerConfig() ? JwtService.getServerConfig() : null,
  cashpowerParams: null,
  confirmNawecPayment: false,
  errors: {},
  // webSocketClient: webSocketClient
}; // can load from localStorage

console.log(initialState);
export default {
  ...initialState
}
