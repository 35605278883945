import ReportService from "../../../services/report.service";

export default {
  async getReport({ state, commit }, { report, view, params }) {
    if(!state[report]) {
        return;
    }
    state[report].loading = true;
    try {
        const r = state[report];
        state[report].loading = true;
        const response = await ReportService.getReport(view ? view : r.name, params);
        commit('mSetReportResult', { report, result: response.data.items })
    } catch (error) {
      //
      
    }
    state[report].loading = false;
  },
};
