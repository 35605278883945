export default {


  mSetLocations(state, data) {
    state.locations = null;
    let list = [];
    if(data){
      list = data
      
    }
    state.locations = list
    console.log("location", state.locations)
    //console.log("locations", locations)
 /*    let list = []
    if(locations){
      list= locations
    }
    state.locations=list */
    //state.locations = locations;
   // console.log("state locations", state.locations)
   /*  state.locations=null;
    let list =[]
    if(data){
      list=data
    }
    state.locations = list
    console.log("locationsss", state.locations) */

  },
  mSetUniqueLocations(state, data){
    state.uniqueLocations = null
    let list = []
    if(data){
      list = data
    }
    state.uniqueLocations = list
    console.log("unique locationsss", state.uniqueLocations)
  },
  mSetFilteredLocation(state, filteredLocation) {
    state.filteredLocation = filteredLocation;
    console.log("mSetLocationFilter", state.filteredLocation)
  },

}
