import ApiService from "./api.service";
import PublicApiService from "./public-api.service";

const PayService = {
  createIntent(payload) {
    return PublicApiService.post("/p/pay/create-payment-intent", payload);
  },
  calculateFrom(payload) {
    return PublicApiService.post("/p/pay/calculate-from", payload);
  },
  calculateTo(payload) {
    return PublicApiService.post("/p/pay/calculate-to", payload);
  },
  confirmIntent(payload) {
    return PublicApiService.post("/p/pay/confirm-payment", payload);
  },
  getIntent(secret) {
    return ApiService.query(`/p/pay/intent/${secret}`);
  },
  getTrueLayerIntent(secret) {
    return ApiService.query(`/p/pay/truelayer/status/${secret}`);
  },
  getIntentA(secret) {
    return ApiService.query(`/service/nawec-test/${secret}`);
  },
  sendSMS(payload) {
    return ApiService.post('/service/nawec-test/action', payload);
  },
  getVerificatioDetails(secret) {
    return ApiService.query(`/p/pay/verification/${secret}`);
  },
}



export default PayService;
