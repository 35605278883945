export default () => {
  return new Promise(function(resolve) {
    resolve({
      common: {
        "title": "Contoh sederhana",
        "subtitle": "Ini halaman kosong. Mulai buat halaman Anda dari sini.",
        "error_with_status": "Terjadi kesalahan di server",
        "404": "Halaman ini tidak dapat ditemukan.",
        "404_subtitle": "Anda bisa tinggal dan bersantai di sini, atau kembali ke awal.",
        "back": "kembali ke beranda",
        "error_without_status": "Terjadi kesalahan di server",
        "contact_title": "Kutipan Proyek Gratis",
        "contact_title2": "Katakan Halo kepada Kami",
        "contact_subtitle": "Apakah Anda memiliki pertanyaan? Apakah Anda siap untuk mengurangi biaya dan membuat pendapatan baru? Atau Anda hanya ingin menyapa? Kirim pesan kepada kami.",
        "form_name": "Siapa nama Anda? *",
        "form_email": "Apa itu email Anda? *",
        "form_phone": "Berapa nomor telepon Anda?",
        "form_company": "Apa perusahaan Anda?",
        "form_message": "Tulis pesan Anda di sini",
        "form_terms": "Saya telah membaca dan menerima Ketentuan",
        "form_privacy": "Layanan & Kebijakan Privasi *",
        "form_send": "Kirim Pesan",
        "login": "masuk",
        "login_create": "Buat akun baru",
        "login_or": "Atau masuk dengan email",
        "login_email": "Email",
        "login_password": "Kata Sandi",
        "login_remember": "Ingat",
        "login_forgot": "Lupa Kata Sandi",
        "continue": "Lanjutkan",
        "login_title": "Selamat datang kembali",
        "login_subtitle": "Silakan masuk untuk melanjutkan",
        "register": "Daftar",
        "register_already": "Sudah punya akun?",
        "register_or": "Atau daftar dengan email",
        "register_name": "Nama? *",
        "register_email": "Email? *",
        "register_password": "Kata Sandi",
        "register_confirm": "Konfirmasi Kata Sandi",
        "register_title": "Senang bertemu Anda :)",
        "register_subtitle": "Cukup daftar untuk bergabung dengan kami",
        "accept": "Setuju",
        "en": "English",
        "de": "Deutsch",
        "zh": "简体中文",
        "pt": "󠁥󠁮󠁧󠁿Português",
        "id": "󠁥󠁮󠁧󠁿Bahasa Indonesia",
        "ar": "󠁥󠁮󠁧󠁿العربيّة",
        "notif_msg": "Ini adalah contoh pesan yang biasa digunakan untuk informasi penting kepada pengunjung atau pengguna. Silahkan klik tombol disamping untuk menutup pesan ini."
      },
      mobileLanding: {
        "header_language": "bahasa",
        "header_theme": "Mode tema",
        "header_dark": "gelap",
        "header_light": "terang",
        "header_feature": "fitur",
        "header_showcase": "showcase",
        "header_testimonials": "testimoni",
        "header_faq": "faq",
        "header_contact": "kontak",
        "banner_title": "Selamat datang di",
        "banner_titlestrong": "pusat komando",
        "banner_desc": "Ini adalah alat lengkap untuk melacak seberapa baik kinerja kampanye Anda.",
        "counter_downloads": "unduhan",
        "counter_transaction": "transaksi",
        "counter_ratting": "rating",
        "feature_title": "fitur",
        "feature_title1": "Tingkatkan Penghasilan Aplikasi Seluler",
        "feature_desc1": "Tingkatkan penjualan & konversi seluler menggunakan aplikasi seluler yang sepenuhnya asli. Dapatkan hingga 5X lebih banyak konversi daripada web seluler.",
        "feature_title2": "Buat dan kelola di ponsel",
        "feature_desc2": "Anda dapat memilih parameter seperti tujuan dan audiens Anda. Anda juga dapat mengelola karya kreatif, menulis, menyalin, dan meninjau pada perangkat yang berbeda.",
        "feature_title3": "Lihat cara kerjanya",
        "feature_desc3": "Kami menawarkan solusi agar Anda dapat bekerja dengan mudah, namun Anda melakukan bisnis.",
        "feature_watch": "Tonton Video",
        "feature_video": "Untuk Aplikasi Seluler - mengotomatiskan iklan seluler",
        "showcase_title": "Ini alat yang Anda butuhkan",
        "faq_subtitle": "Ada pertanyaan? Lihat pertanyaan umum kami untuk menemukan jawaban Anda.",
        "testimonial_title": "testimonials",
        "company_title": "Dipercaya oleh organisasi di seluruh dunia",
        "news_title": "Berita Terbaru",
        "news_desc": "Baca lebih lanjut tentang berita terbaru dan acara khusus kami",
        "footer_text": "Apa yang Anda tunggu? Unduh Sekarang!"
      }
    })
  })
}
