
export default {
    // company_users
    users: {
        name: 'acl_users',
        loading: false,
        result: [],
    },
    agencies: {
        name: 'acl_companies',
        loading: false,
        result: [],
    },
    companies: {
        name: 'acl_companies',
        loading: false,
        result: [],
    },
    agency: {
        name: 'get_company',
        loading: false,
        result: null,
        type: "item",
    },
    currencies: {
        name: 'acl_currencies',
        loading: false,
        result: null,
    },
    branches: {
        name: 'company_branches',
        loading: false,
        result: [],
    },
    wallets: {
        name: 'get_wallets',
        loading: false,
        result: [],
    },
    apiKeys: {
        name: 'user_keys',
        loading: false,
        result: [],
    },
    payout: null,
    withdrawalCode: null,
    secretCode: null,
    documentType:null,
    documentNumber:null,
    expiryDate:null,
    issueDate:null
}
