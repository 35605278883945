import { v4 as uuidv4 } from 'uuid';
import JwtService from "@/services/jwt.service";

import {
    getFromStorage,
    saveInStorage,
} from '@/services/localstorage.service';

const key = "active-wallet";
const VERSION = '0.0.50001';

export const createWallet = () => {
    const value = getFromStorage(key);
    if(value && value.version && value.version === VERSION) {
        if (JwtService.getToken() == null && value.public) {
            return value;
        } else if (JwtService.getToken() && !value.public) {
            return value;
        }
        
    }
    // alert("createWallet");
    // if(value && value.isPublic) {
    //     if(value.version && value.version === VERSION) {
    //         return value;
    //     }
    // } else if(value) {
    //     return value;
    // }
    const randomID = uuidv4().toUpperCase();
    const date = Date.now() + Math.random().toString(20).substr(2, 5);
    const code = 'PUBLIC-' + randomID + "-" + date;
    const userCode = 'USER-' + randomID + "-" + date;

    const wallet = {
        code,
        fullname: 'Public',
        public: true,
        userCode,
        username: null,
        now: Date.now(),
        version: VERSION,
    };
    setActiveWallet(wallet);
    return wallet;
}

export const getDefaultWallet = (wallets) => {
    if(!wallets) {
        return null;
    }
    let wallet = wallets.find((item) => item.currentBalance && item.type == "SERVICEGMD");
    if(!wallet) {
        wallet = wallets.find((item) => item.currentBalance && item.type == "MERCHANT");
    }
    if(!wallet) {
        wallet = wallets.find((item) => item.currentBalance && item.type == "NAWECAGENT");
    }
    if(!wallet) {
        wallet = wallets.find((item) => item.type == "SERVICEGMD");
    }
    if(!wallet) {
        wallet = wallets.find((item) => item.type == "MERCHANT");
    }
    if(!wallet) {
        wallet = wallets.find((item) => item.type == "NAWECAGENT");
    }
    if(!wallet && wallets && wallets.length) {
        wallet = wallets[0];
    }

    return wallet;
};

export const setActiveWallet = (wallet) => {
    // console.log('setActiveWallet', wallet);
    wallet.version = VERSION;
    saveInStorage(key, wallet);
    return wallet;
}