// import { Ability } from '@casl/ability';
import JwtService from '@/services/jwt.service';
import {
  createWallet,
} from '@/model/wallet.model';

import defineAbilitiesFor from '../../../plugins/ability';

const user = JwtService.getUser();
const wallets = JwtService.getWallets();

const wallet = createWallet();

// let _transactionDisplay = JwtService.getObject('_transactionDisplay');
// console.log("user", user);

const initialState = {
  isAuthenticated: user ? true : false,
  user: user ? user : {},
  loginDialog: false,
  signupDialog: false,
  addressDialog:false,
  detailDialog:false,
  qrCodeDialog:false,
  userProfileDetailDialog:false,
  userProfileAddressDialog:false,
  changePasswordDialog:false,
  walletDialog:false,
  errors: {},
  selectedUser:null,
  editUser: null,
  walletList:{},
  subAgents: {},
  mobileDialog: false,
  notificationFormDialog: false,
  // webSocketClient: webSocketClient
}; // can load from localStorage

console.log(initialState);
export default {
  ...initialState,
  wallet,
  wallets,
  serverConfig: null,
  walletDialog: false,
  walletDialogKey: null,
  walletParams: null,
  listWalletDialog: false,
  riskLevelDialog: false,
  lockWalletDialog: false,
  accessRights: defineAbilitiesFor(user, wallet),
  ability: defineAbilitiesFor(user, wallet),
  userWallets:[]
  // transactionDisplay: _transactionDisplay ? _transactionDisplay : [],
}
