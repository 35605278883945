export default {
  mSetReportResult(state, { report, result }) {
    try {
      const r = state[report];
      if(!r) {
        return;
      }
      const type = r.type ? r.type : null;
      if(type && type === 'item') {
        state[report].result = result.length ? result[0] : null;
      } else {
        state[report].result = result;
      }
    } catch(error) {
      //
      alert(error);
    }
  },
}
