import { Basket } from "../../../lib/ecommerce";
import NAWECService from '@/services/nawec.service';
import calculator from '../../../lib/calculator';
import { printReceipt } from "../../../lib/printer";
let basket = new Basket();

const getSummary = (order) => {

    const message = JSON.parse(order.apiMessage);
    const stsPaymentResult = message.stsPaymentResults[0];
    const request = message.request;
    return {
        secret: order.secret,
        operator: 'Pa Essa Jabang',
        customerName: stsPaymentResult.customerName,
        token: stsPaymentResult.listToken[0],
        receipt: stsPaymentResult.receipt,
        tenderedAmount: request.amount,
        serviceFee: request.serviceFee,
        units: stsPaymentResult.units,
        mobileNumber: request.mobileNumber,
        meterNumber: request.meterNumber,
    };

}
/* const getDetails= (details)=>{
    return{
        operator:details.operator,
        number:details.number
    }
} */
export default {
    async aItemIntent() {
        try {
            //   const response = await PayService.createIntent(payload);
            //   const data = response.data;
            //   state.paymentIntent = data;
        } catch (error) {
            // 
        }
    },
    async aAddItem({ commit }, item) {
        basket.addItem(item);
        // alert(basket.getTotal());
        commit('mUpdateBasket', basket);
        commit('mSetActiveItem', basket.getLastAddedItem());
        commit('mShowReceipt', false);
        // mSetActiveItem
    },
    async aStart({ commit }) {
        basket = new Basket();
        commit('mSetStatus', 'order');
        commit('mUpdateBasket', basket);
        commit('mSetActiveItem', null);
        commit('mShowReceipt', false);
        commit('mSetSecret', null);
    },
    async aUpdateBasket({ commit }) {
        commit('mUpdateBasket', basket);
        commit('mShowReceipt', false);
    },
    async aFindCustomer({ commit }) {
        commit('mUpdateProcessState', true);
        try {
            // basket.
            const item = basket.getLastAddedItem();
            const customer = item.customer;
            const total = basket.getTotal();
            const result = calculator.chargeCalculator(total);

            const payload = {
                amount: total,
                serviceFee: result.serviceFee,
                meterNumber: customer.meterNumber,
                mobileNumber: customer.mobileNumber,
            };
            // console.log('item', item);
            item.summary = result;
            const response = await NAWECService.createIntent(payload);
            const data = response.data;
            item.customer.name = data.customerName;

            const summary = {
                operator: 'Pa Essa Jabang',
                customerName: data.customerName,
                token: null,
                tenderedAmount: total,
                serviceFee: result.serviceFee,
                units: result.units,
            };
            basket.setSecret(data.secret);
            commit('mSetSecret', data.secret);
            commit('mSetSummary', summary);
            commit('mUpdateBasket', basket);
            commit('mSetStatus', 'confirm');
            commit('mUpdateProcessState', false);
            commit('mShowReceipt', true);

        } catch (error) {
            commit('mUpdateProcessState', false);
            alert('Customer not found');
            // err
        }
    },
    async aDetails({ commit, state }) {
        commit('mSetNumber', number);
        commit('mSetOperator', operator);
        let number = state.number
        let operator = state.operator

    },
    async aConfirm({ commit, state, dispatch }) {
        commit('mUpdateProcessState', true);

        try {
            const payload = {
                secret: state.secret,
            };
            const response = await NAWECService.confirm(payload);
            const data = response.data;
            // console.log('item', item);

            basket.setSecret(data.secret);
            commit('mSetSecret', data.secret);
            commit('mSetConfirmedItem', data);
            commit('mSetStatus', 'view');
            commit('mSetSummary', getSummary(data));
            // 

            dispatch('aPrint');

        } catch (error) {
            alert('Cannot confirm order. Contact system administrator');
            //
        }
        commit('mUpdateProcessState', false);
    },

    async aPrint({ commit, state }) {
        try {

            let data = state.confirmedItem;
            if (!data || (data.secret !== state.secret)) {
                const response = await NAWECService.getIntent(state.secret);
                data = response.data;
            }

            commit('mSetConfirmedItem', data);
            printReceipt(state.confirmedItem);


            /// commit('mSetSummary', summary);

        } catch (error) {
            //
        }
    },
    async aSetDetails({ commit, state }) {
        try {
            const detail = {
                number: state.number,
                operator: state.operator
            }
            /* let number = state.number
            let operator = state.operator */

            commit('setDetails', detail)

        } catch (error) {
            console.log(error)
        }
    },


}