import ApiService from "./api.service";
import PublicApiService from "./public-api.service";

const NAWECService = {
  createNawecPaymentIntent(payload) {
    return PublicApiService.post("/p/pay/create-intent/cashpower", payload);
  },
  confirmNawecPaymentIntent(payload) {
    // console.log('confirm nawec payment intent', payload)
    return ApiService.post("/service/electricity-purchase/confirm", payload);
  },
  confirm(payload) {
    return ApiService.post("/service/nawec-test/confirm", payload);
  },
  getIntent(secret) {
    return ApiService.query(`/service/nawec-test/${secret}`);
  },
  sendSMS(payload) {
    return ApiService.post('/service/nawec-test/action', payload);
  },
  
}

export default NAWECService;