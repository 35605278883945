//import JwtService from '@/services/jwt.service';


export default {
  mSetError(state, error) {
    state.errors = error;
  },
  mSetLoading(state, data){
    state.loading = data;
  },
  mBuyCashpower(state, params ) {
    console.log('buy cashpower', params)
    state.cashpowerParams = params;
  },
  mSetNawecPaymentIntent(state, data) {
    state.nawecPaymentIntent = data;
    state.errors = {};
  },
  mSetConfirmNawecPayment(state, data){
    state.confirmNawecPayment = false;
    state.confirmNawecPayment = data;
  },
  mSetSendNawecToken(state, data){
    state.sendNawecToken = data;
  },
  mSetPurchaseAmount(state, data){
    state.amountPurchase = data;
  },
  mSetMeterNumber(state, data){
    state.meterNumber = data
  }

}
