import ContactService from '@/services/contact.service';

export default {
  async aLoadContacts({ commit, state }, payload) {
    state.loading = true;
    commit("mSetContactList", []);
    try {
      
      const result = await ContactService.getContacts(payload.type);
      if (result.data) {
        commit("mSetContactList", result.data);
      }
    } catch (error) {
      console.log("error", error);
    }
    state.loading = false;
  },
}
