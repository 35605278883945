import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import themes from "@tenant/theme";
// console.log('themes', themes);

Vue.use(Vuetify);


const opts = {
    theme: {
        themes,
    }
};

export default new Vuetify(opts);
