import AirtimeService from '@/services/airtime.service';
import NawecService from '@/services/nawec.service';
import PublicAirtimeService from '@/services/public-airtime.service';
import PublicNawecService from '@/services/public-nawec.service';
import PayService from '@/services/pay.service';
import PublicPayService from '@/services/public-pay.service';
import JwtService from '@/services/jwt.service';

const services = {
    AirtimeService,
    NawecService,
    PublicAirtimeService,
    PublicNawecService,
    PayService,
    PublicPayService,
}
const get = (service) => {
    const s = JwtService.getToken() ? service : 'Public' + service;
    // console.log('service', s);
    return services[s] ? services[s] : null;
};

export default {
    get,
}