export default {
  airtimeDialog: false,
  airtimeDialogKey: null,
  airtimeSellDialog: false,
  airtimeSellDialogKey: null,
  airtimeParams: null,
  loginDialog:false,
  loginDialogKey:null,
  loginParams:null,
  exchangeRates: null,
  configSet: false,
  bundles: [],
  transactionDetail: null,
}