import Factory from '@/services/factory';
import PublicPayService from '@/services/public-pay.service';

export default {
  async aCreatePaymentIntent({ state, commit }, payload) {
    try {
      const response = await Factory.get("PayService").createIntent(payload);
      const data = response.data;
      state.paymentIntent = data;
    } catch (error) {
      let message;
      try {
        message = error.response.data.message;
      } catch(error) {
        message = "Error => Transaction failed "
      }
      commit("mShowAlert", message, {
        root: true,
      });
      throw message;
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async aGetPaymentIntent({}, secret) {
    try {
      const response = await Factory.get("PayService").getIntent(secret);
      const data = response.data;
      return data;
    } catch (error) {
      // 
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async aCalculateFrom({}, payload) {
    try {
      const response = await PublicPayService.calculateFrom(payload);
      const data = response.data;
      return data;
    } catch (error) {
      // 
      // alert(error);
    }
  },
  // eslint-disable-next-line no-empty-pattern
  async aCalculateTo({}, payload) {
    try {
      const response = await PublicPayService.calculateTo(payload);
      const data = response.data;
      return data;
    } catch (error) {
      // 
    }
  },
  async aCustomer({ state }) {
    try {
      const response = await Factory.get("PayService").customer();
      const data = response.data;
      state.customer = data;
    } catch (error) {
      // 
    }
  },
  async aGetCards({ state }) {
    try {
      const response = await Factory.get("PayService").getCards();
      const data = response.data;
      console.log('aGetCards data', data);
      state.cards = [];
      const cards = data.map((item) => {
          const result = {};
          result.id = item.id;
          result.name = `•••• ${item.card.last4} - Expiry ${item.card.expMonth}/${item.card.expYear} (${item.card.country})`;
          return result;
        });
        state.cards = cards;
      // state.cards = data.map((item) => {
      //   const result = {};
      //   result.id = item.id;
      //   result.name = `•••• ${item.methodDetails.last4} - Expiry ${item.methodDetails.expiryMonth}/${item.methodDetails.expiryYear}`;
      //   return result;
      // });
    } catch (error) {
      // 
    }
  },

  async aGetPayouts({state}){
    try{
      const response= await Factory.get("PayService").getPayouts();
      const data = response.data
      state.payouts = data.map((item)=>{
        const result = {};
        result.id = item.id;
        console.log(result)
        return result
      });
    }catch(error){
      //
    }
  },
  async aConfirmPaymentIntent({ state }, payload) {
    try {
      await Factory.get("PayService").confirmIntent(payload);
      // const data = response.data;
      state.paymentIntent = null;
    } catch (error) {
      // 
    }
  },
  async aConfirmWalletTransaction({ state, commit }, payload) {
    try {
      await Factory.get("PayService").confirmWalletTransaction(payload);
      // const data = response.data;
      state.paymentIntent = null;
    } catch (error) {
      let message;
      try {
        message = error.response.data.message;
      } catch(error) {
        message = "Error => Transaction failed "
      }
      commit("mShowAlert", message, {
        root: true,
      });
      throw new Error(message);
    }
  },
  async aPayInvoice({ state }, payload) {
    try {
      await Factory.get("PayService").payInvoice(payload);
      // const data = response.data;
      state.paymentIntent = null;
    } catch (error) {
      let message;
      try {
        message = error.response.data.message;
      } catch(error) {
        message = "Error => Transaction failed "
      }
      // commit("mShowAlert", message, {
      //   root: true,
      // });

      throw new Error(message);
    }
  },

}
