export default {
  mUpdateRecipientDialog(state, dialog) {
    state.recipientDialog = dialog;
  },
  mSetPayOut(state, payOut) {
    state.payOut = payOut;
  },
  mSetWithdrawalCode(state, withdrawalCode) {
    state.withdrawalCode = withdrawalCode;
  },
  mSetSecretCode(state, secretCode){
    state.secretCode = secretCode
  },
  mSetDocumentType(state, documentType){
    state.documentType=documentType
  },
  mSetDocumentNumber(state, documentNumber){
    state.documentNumber= documentNumber
  },
  mSetIssueDate(state, issueDate){
    state.issueDate = issueDate
  },
  mSetExpiryDate(state, expiryDate){
    state.expiryDate = expiryDate
  },
  mSetPayOutAPIs(state, data){
    state.payOutAPIs = data
  }
}
