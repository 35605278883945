const defaultOption = {
    display_code: "Wallet",
    icon: "~/images/icons/wallets-default-white.png",
    color: '#64B5F6',
};

const options = {
    NAWECAGENT: {
        display_code: "Merchant",
        icon: "~/images/icons/wallets-merchant-white.png",
        color: '#81D4FA',
    },
    NAWECSUBAGENT: {
        display_code: "Sub Merchant",
        icon: "~/images/icons/wallets-sub-merchant-white.png",
        color: '#A5D6A7',
    },
    SERVICEGMD: {
        display_code: "Wallet",
        icon: "~/images/icons/wallets-default-white.png",
        color: '#64B5F6',
    }
};

const getOption = (type) => {
    if(options[type]) {
        return { ...options[type] };
    } 
    const option = { ...defaultOption };
    option.display_code = type;
    return option;
};
const transformList = (data) => {
    
    try {
        return data.map((item) => {
            const option = getOption(item.type);
            return { ...item, ...option };
        });
    } catch(error) {
        return data;
    }
};

export default {
    transformList,
}