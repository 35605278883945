import numeraljs from "numeraljs";

export const createSendMoneyParams = (params = {}) => {
  const result = {
    fromWalletCode: null,
    toWalletCode: null,
    fromAmountNet: null,
    fromAmount: null,
    fromCurrencyCode: "GBP",
    transferType: "INSTANT-BANK",
    transferCharge: 0.00,
    deliveryCharge: 0.00,
    exchangeRate: null,
    deliveryType: "COLLECTION",
    toAmount: null,
    toCurrencyCode: "GMD",
  };
  return { ...result, ...params };
};

export const createRecipient = (params = {}) => {
  const result = {
    name: null,
    mobileNumber: null,
    bank: null,
    bankAccountNumber1: null,
    bankAccountNumber2: null,
    address1: null,
    address2: null,
    address3: null,
    address4: null,
    zipPostalCode: null,
    country: null,
  };
  return { ...result, ...params };
};

export const calculate = (name, inputParams, exchangeRates, transferTypes, deliveryTypes) => {
  const params = { ...inputParams };
  console.log("transferTypes", transferTypes);
  const exchangeRate = exchangeRates.find((item) => item.serviceCode === "MONEYTRANSFER" && item.fromCurrencyCode === params.fromCurrencyCode && item.toCurrencyCode === params.toCurrencyCode);
  if (!exchangeRate) {
    throw `Exchange rate does not exist : ${params.fromCurrencyCode} to ${params.toCurrencyCode}`;
  }
  const transferType = transferTypes.find((item) => item.code === params.transferType);
  if (!transferType) {
    throw `Transfer type does not exist : ${params.transferType}}`;
  }
  const deliveryType = deliveryTypes.find((item) => item.code === params.deliveryType);
  if (!deliveryType) {
    throw `Delivery type does not exist : ${params.deliveryType}}`;
  }
  params.exchangeRate = exchangeRate.rate;
  const input1 = params.fromAmount ? params.fromAmount : 0.0;
  const transferCharge = transferType.amount;
  const deliveryCharge = deliveryType.amount;
  
  params.transfer_charge = transferCharge;
  params.delivery_charge = deliveryCharge;

  params.transferCharge = transferCharge;
  params.deliveryCharge = deliveryCharge;

  const charge = numeraljs(transferCharge)
    .add(deliveryCharge)
    .value();
  let amountToConvert = 0;
  const input2 = params.toAmount ? params.toAmount : 0.0;
  if (name === "fromAmount") {
    amountToConvert =
      input1 && input1 > charge
        ? numeraljs(input1)
            .subtract(charge)
            .value()
        : 0.0;
    params.toAmount = numeraljs(amountToConvert).multiply(exchangeRate.rate);
    params.toAmount = params.toAmount ? numeraljs(params.toAmount).format("0.00") : null;
  } else {
    params.fromAmount = input2
      ? numeraljs(input2)
          .divide(exchangeRate.rate)
          .add(charge)
          .value()
      : null;
    params.fromAmount = params.fromAmount ? numeraljs(params.fromAmount).format("0.00") : null;
  }

  amountToConvert =
    params.fromAmount && params.fromAmount > charge
      ? numeraljs(params.fromAmount)
          .subtract(charge)
          .value()
      : 0.0;
  params.amountToConvert = numeraljs(amountToConvert).format("0,0.00");
  params.fromAmountNet = amountToConvert;
  return params;
};
