const charges = [
    {
        start: 0,
        end: 35,
        value: 4,
    },
    {
        start: 35,
        end: 50,
        value: 4,
    },
    {
        start: 50,
        end: 100,
        value: 5,
    },
    {
        start: 100,
        end: 500,
        value: 8,
    },
    {
        start: 500,
        end: 1000,
        value: 12,
    },
    {
        start: 1000,
        end: 10000000000000000,
        type: 'percentage',
        value: 1,
    },
];

export default charges;