import ApiService from "./api.service";

const GiftCardService = {
/*   createAirtimePaymentIntent(payload) {
    console.log('create airtime payment intent', payload)
    return ApiService.post("/service/airtime/create-payment-intent", payload);
  },
  confirmAirtimePaymentIntent(payload) {
    console.log('confirm airtime payment intent', payload)
    return ApiService.post("/service/airtime/confirm", payload);
  }, */

  getGiftCards(){
    return ApiService.query("/p/pay/products/gift-coupon-cards/",{})
  }
  
}

export default GiftCardService;