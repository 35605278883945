export default {
    payouts: [],
    payout: null,
    withdrawalCode: null,
    secretCode: null,
    documentType:null,
    documentNumber:null,
    expiryDate:null,
    issueDate:null,
    recipientDialog: false,
    payOutAPIs: [],
}
