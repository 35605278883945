import ApiService from "./api.service";

const Service = {
  createPaymentIntent(payload) {
    return ApiService.post(`/pay/commerce/create-payment-intent`, payload );
  },
  confirm(payload) {
    return ApiService.post(`/pay/commerce/confirm`, payload);
  },
  getMyTickets() {
    console.log("my tickets");
    return ApiService.query(`/commerce/ticket/my-tickets`);
  },
  getScannedTickets() {
    console.log("scanned tickets");
    return ApiService.query("/commerce/ticket/scanned-tickets");
  },
  getActiveTickets(params){
    console.log("params", params);
    // return null;
    return ApiService.query(`/commerce/ticket`, { params })
  },
  getReport(params) {
    return ApiService.query(`/commerce/report`, { params });
  },
}

export default Service;