// import ApiService from "./api.service";
import PublicApiService from "./public-api.service";

const AirtimeService = {
  createAirtimePaymentIntent(payload) {
    return PublicApiService.post("/p/pay/create-intent/airtime", payload);
  },
  confirmAirtimePaymentIntent() {
    return null;
  },
  
}

export default AirtimeService;