import PayService from "@/services/pay.service.js";
// import JwtService from "@/services/jwt.service";
import NAWECService from "@/services/nawec.service";
// const wallets = JwtService.getWallets();
export default {
  // async aLoadTransactions({commit}, payload) {
  //   try {

  //     const result = await TransactionService.getTransactions(payload.type);
  //     if (result.data) {
  //       commit("mSetTransactionList", result.data);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // },
  async aRefreshTransactionDetails({ dispatch, state }) {
    const type = state.type ? state.type : "DEFAULT";
    dispatch("aLoadTransactionDetails", type);
    //dispatch("aLoadPendingTransactionDetails",type)
  },
  async aRefreshTransactionPendingDetails({ dispatch }) {
    //const type = state.type ? state.type : "DEFAULT";
    dispatch("aLoadPendingTransactionDetails")
  },

  async aLoadTransactionDetails({ commit, state, rootState }, type) {
    state.loading = true;
    state.type = type;
    const code = rootState.users.wallet.code;

    await PayService.getTransactions(code, type)
      .then((response) => {
        commit("mUpdateTransactions", response.data);
      })
      .catch((error) => {
        commit("mUpdateTransactions", []);
        console.log("getTransactions error", error);
      });
    state.loading = false;
  },


  async aLoadPendingTransactionDetails({ commit, state, rootState }) {
    state.loading = true;
    const code = rootState.users.wallet.code;
    await PayService.getPendingTransactions(code)
      .then((response) => {
        
        console.log("transactionsss response.data", response.data.items);
  
        commit("mSetPendingTransactions", response.data.items);
        //state.pendingTransactions= response.data.items
        console.log("pending", state.pendingTransactions)

      })
      .catch((error) => {
    
        commit("mSetPendingTransactions", []);
        console.log("get pending transactions error", error);
      });
    state.loading = false;
  },

  async aGetWalletUsingCode({state, code}){
    state.loading = true
    try{
      const result = await PayService.getWalletUsingCode(code)
      if(result.data){
        return result.data
      }else{
        console.log("No data available")
        return null
      }
    } catch(error){
      console.log("error", JSON.stringify(error))
    }
  },
  async aGetPaymentIntentUsingSecret(store, secret) {
    console.log("getIntent");
    try {
      const result = await NAWECService.getIntent(secret);
      if (result.data) {
        // store.commit("mUpdateNawecToken", result.data);
        return result.data;
      } else {
        console.log("data unavailiable");
        return null;
      }
    } catch (error) {
      console.log("error3 get intent", JSON.stringify(error));
    }
  },
};
