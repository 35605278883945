const AIRTIME_TOPUP_CODE = "AIRTIME";
const AIRTIME_SERVICE_CODE = "AIRTIME";
const CASHPOWER_TOPUP_CODE = "CASHPOWER";
const CASHPOWER_SERVICE_CODE = "CASHPOWER";
const COMMERCE_SERVICE_CODE = "COMMERCE";

const TOPUP_CODE = "TOPUP";

const MONEY_TRANSFER_CONTACT_CODE = "SENDMONEY";
// Only has the wallet code with no prefilled amount
const TOPUP_INITIATOR_CODE = "TOPUPINITIATOR";

const TOPUP_REQUEST_CODE = "TOPUPREQUEST";
const EGIFTCOUPON_CARD = "EGIFTCOUPONCARD";
const ETICKET_CODE= "TICKET";

const REQUESTPAYMENT_TOPUP_CODE = "REQUESTPAYMENTTOPUP";
const REQUESTPAYMENT_CODE = "REQUESTPAYMENT";
const MONEYTRANSFER_TOPUP_CODE = "MONEYTRANSFER";

export { MONEY_TRANSFER_CONTACT_CODE, AIRTIME_TOPUP_CODE, AIRTIME_SERVICE_CODE, CASHPOWER_TOPUP_CODE, MONEYTRANSFER_TOPUP_CODE, CASHPOWER_SERVICE_CODE, TOPUP_CODE, REQUESTPAYMENT_TOPUP_CODE, TOPUP_INITIATOR_CODE, TOPUP_REQUEST_CODE, REQUESTPAYMENT_CODE, EGIFTCOUPON_CARD, ETICKET_CODE, COMMERCE_SERVICE_CODE };
