export default {
  mSetTransactionDetail(state, transactionDetail) {
    state.transactionDetail = null;
    state.transactionDetail = { ...transactionDetail };
  },
  mBuyAirtime(state, params) {
    state.airtimeParams = params;
    state.airtimeDialog = true;
    state.airtimeDialogKey = new Date().toLocaleString();
  },
  mSellAirtime(state, params) {
    state.airtimeParams = params;
    state.airtimeSellDialog = true;
    state.airtimeSellDialogKey = new Date().toLocaleString();
  },
  mUpdateAirtimeDialog(state, value) {
    state.airtimeDialog = value;
  },
  mUpdateAirtimeSellDialog(state, value) {
    state.airtimeSellDialog = value;
  },
  mResetParams(state) {
    state.airtimeParams = null;
  },
  mLogin(state, params) {
    // console.log("login dialog", params);
    state.loginParams = params;
    state.loginDialog = true;
    state.loginDialogKey = new Date().toLocaleDateString();
  },
  mUpdateLoginDialog(state, value) {
    state.loginDialog = value;
  },
  mResetParam(state) {
    state.loginParams = null;
  },
  mSetConfig(state, { exchangeRates }) {
    state.exchangeRates = exchangeRates;
  }
};
