import GiftCardService from "@/services/giftcard.service";
export default {
    async aLoadGiftCards({ commit }) {
        try {
          const response = await GiftCardService.getGiftCards();
          if (response && response.data) {
            commit("mSetGiftCards", response.data)
          } else {
            commit("mShowAlert", "Error => cannot load gift cards ", { root: true });
          }
        } catch (error) {
          commit("mShowAlert", "Error => cannot load gift cards " + error.message, { root: true });
        }
      },
};
