import Base from "./base";
class IdentityUser extends Base {
  constructor() {
    super();
    this.id = null;
    this.code = null;
    this.externalUserCode = null;
    this.name = null;
    this.title = null;
    this.firstname = null;
    this.middlename = null;
    this.lastname = null;
    this.type = null;
    this.batchCode = null;
    this.segment = null;
    this.email = null;
    this.mobileNumber = null;
    this.telephoneNumber = null;
    this.address1 = null;
    this.address2 = null;
    this.address3 = null;
    this.city = null;
    this.zipPostalCode = null;
    this.country = null;
    this.company = null;
    this.requestType = "internal";
    this.tenant = null;
    this.geoChannel = null;
    this.geoCountry = null;
    this.geoLocation = null;
    this.latitude = null;
    this.longitude = null;
    this.status = null;
    this.profileSize = null;
    this.profileType = null;
    this.profileUrl = null;
    this.loggedInUserCode = null;
    this.loggedInUserFullname = null;
    this.profileExtension = null;
    this.profileContent = null;
    this.description = null;
    this.dob = null;
    this.statusRequestCount = null;
    this.statusCheckCount = null;
    this.lastStatusCheck = null;
    this.itemsRequested = "country,name,address,id,selfie";
    this.createdDate = null;
    this.updatedDate = null;
    this.createdAt = null;
    this.updatedAt = null;
    
  }
}

export {
    IdentityUser,
}