<template>
  <router-view />
</template>

<script>
// import { version } from '../package.json';


export default {
  name: 'App',
  components: {
  },
  data() {
    return {};
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    performLogout() {
      // this.$store.dispatch('performLogout');
    },
  },
};
</script>
<style>
.v-toolbar__content {
  padding-left: 0;
  padding-right: 0;
}
.logo {
  height: 50px;
  width: auto;
}
.cta {
  color: #2196f3;
  cursor: pointer;
}
</style>
