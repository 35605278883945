import ApiService from "./api.service";

const PayService = {
  createIntent(payload) {
    return ApiService.post("/pay/create-payment-intent", payload);
  },
  customer() {
    return ApiService.post("/pay/customer", {});
  },
  getCards() {
    return ApiService.post("/pay/payment-methods", {});
  },
  confirmIntent(payload) {
    return ApiService.post("/pay/confirm-payment", payload);
  },
  confirm(payload) {
    return ApiService.post("/service/nawec-test/confirm", payload);
  },
  getIntent(secret) {
    return ApiService.query(`/pay/intent/${secret}`);
  },
  sendSMS(payload) {
    return ApiService.post("/service/nawec-test/action", payload);
  },
  getTransactions(code, transactionType) {
    const path = transactionType && transactionType !== "DEFAULT" ? `/wallet/list/transactions/${code}/${transactionType}` : `/wallet/list/transactions/${code}`;
    return ApiService.query(path);
  },
  getPayOuts() {
    return ApiService.query("/wallet/payouts/", {});
  },

  getPendingTransactions(walletCode){
    //const path= `/wallet/report/my_pending_payment_transactions/${walletCode}`;
    //return ApiService.query(path);
    return ApiService.query(`/wallet/report/my_pending_payment_transactions/${walletCode}`);
  },
  
  getPayOutUsingWithdrawalCode(withdrawalCode) {
    return ApiService.query("/wallet/payout/withdraw/" + withdrawalCode, {});
  },
  getPayOutUsingSecret(secretCode){
    return ApiService.query(`/wallet/payout/${secretCode}`);
  },
  getWalletUsingCode(code){
    return ApiService.query(`/wallet/${code}`);
  },
  confirmWithdrawal(payload) {
    return ApiService.post("/wallet/payout/withdraw", payload);
  },
  confirmPayOutTransfer(payload) {
    return ApiService.post("/wallet/payout/transfer", payload);
  },
  confirmWalletTransaction(payload) {
    return ApiService.post("/pay/wallet/confirm-transaction", payload);
  },
  payInvoice(payload) {
    return ApiService.post("/pay/wallet/invoice", payload);
  },

  getWalletList(){
    return ApiService.query("/p/pay/config/services");
  },
  createWallet(payload){
    return ApiService.post("/wallet", payload)
  },
  walletTransfer(payload){
    return ApiService.post("/wallet/transfer", payload)
  },
  setPayoutCustomerServiceStatus(payload) {
    return ApiService.post("/wallet/payout/update-cs-status", payload)
  },
  updateRecipient(payload) {
    return ApiService.post("/wallet/payout/update-recipient", payload)
  },
  approve(payload) {
    return ApiService.post("/pay/approve", payload);
  },
  confirmPayment(payload) {
    return ApiService.post("/pay/confirm-payment", payload);
  },
  getWhatsAppConfig() {
    return ApiService.query(`/pay/whatsapp/config`);
  },
};

export default PayService;
