import { fromCurrencyCode, toCurrencyCode, currencies } from '../../../lib/currency.js';

export default {
    // wallet,
    formattedTotal: null,
    total: null,
    activeItem: null,
    processing: false,
    showReceipt: false,
    valid: false,
    status: 'order',
    formValid: false,
    // secret: null,
    secret: 'PI-NW-P100067390JABAWNAWACNENCWWAW-3sydr2BVenSB4_rSz4W1D-1633606180053',
    confirmedItem: null,
    summary: null,
    number: '3042637',
    operator: 'QCell',
    secrets: [],
    intents: [],
    bundles: [],
    fromCurrencyCode,
    toCurrencyCode,
    currencies,
    exchangeRates: [],
    dialCode: null,
}