
export default {
  mSetContactType(state, contactType) {
    state.contactType = null;
    state.contactType = contactType;
  },
  mSetContactList(state, data) {
    console.log('meter numbers')
    state.contacts = null;
    let list = [];
    if (data) {
      list = data.filter((item) => item.name && (item.meterNo || item.mobile)).map((item) => {
        if (item.meterNo) {
          const index = item.name + ' ' + item.meterNo;
          item.index = index.toLowerCase();
        } else if (item.mobile) {
          const index = item.name + ' ' + item.mobile;
          item.index = index.toLowerCase();
        }
        return item;
      });
    }
    state.contacts = list;
    // console.log('contacts', list)

    // state.meterNumberContacts = list;
  },
}
