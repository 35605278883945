import colors from 'vuetify/lib/util/colors'

const themes = {
    light: {
      primary: colors.blue,
      anchor: colors.blue,
    },
    dark: {
    },
  };
export default themes;
