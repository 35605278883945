import { validatePhoneNumber } from "../../../services/lib";

import LocationService from "@/services/public-location.service";

export default {
  async aLoadLocations({ commit, state }) {
    state.loading = true;
    try {
      const response = await LocationService.getLocations();

      if (response && response.data) {
        state.locations = response.data.items
        if(!state.filteredLocation){
          state.loading = false
          return state.locations
          
        }
        state.locations = state.locations.filter((item)=>item.location===state.filteredLocation) .map((item) => {
          const phoneNumber = validatePhoneNumber(item.telephoneNumber);
          item.telephoneNumber = phoneNumber ? phoneNumber : item.telephoneNumber;
        
          return item;
        });
        commit("mSetLocations", state.locations);

      } else {
        commit("mShowAlert", "Error => cannot load locations", { root: true });
      }
    } catch (error) {
      commit("mShowAlert", "Error => cannot load locations " + error.message, { root: true });
    }
    state.loading = false;
  },

  async aLoadUniqueLocations({commit, state}){
    state.loading = true
    try{
      const response = await LocationService.getLocations()
      if(response && response.data){
       const unique = Array.from(new Set(response.data.items.map(item => item.location)))
        console.log("uniuqqq", unique)
        commit("mSetUniqueLocations", unique)

      }else{
        commit("mShowAlert", "Error Loading Locations")
      }
    }catch(error){
      commit("mShowAlert", "Error Loading Locations")
    }
    state.loading = false
  }
};
