export default {
    locations: [
    ],
    loading: false,
    location: null,
    uniqueLocations:[],
    filteredLocation:null,
  

}
