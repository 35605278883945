import LocationService from "@/services/public-location.service";
export default {
  async aLoadPayOutAPIs({ commit, state }) {
    state.loading = true;
    try {
      const response = await LocationService.getPayOutAPIs();

      if (response && response.data) {
        commit("mSetPayOutAPIs", response.data.items);
      } else {
        commit("mShowAlert", "Error => cannot load APIs", { root: true });
      }
    } catch (error) {
      commit("mShowAlert", "Error => cannot load locations " + error.message, { root: true });
    }
    state.loading = false;
  },
};
