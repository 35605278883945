import axios from "axios";
import { API_URL, TENANT } from "@/config";

import { version } from "../../package.json";

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    TENANT,
    CLIENT_VERSION: version,
    CLIENT_PLATFORM: 'WEB'
  }
});

const ApiService = {
  init() {
    // Vue.use(VueAxios, axios);
    instance.defaults.baseURL = API_URL;
    instance.defaults.headers.common['TENANT'] = TENANT;
    instance.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status == 403) {
            try {
              // JwtService.destroyToken();
              // eslint-disable-next-line no-empty
            } catch (err) {}
          }
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
        }

        return Promise.reject(error);
      }
    );
  },
  getBaseUrl() {
    return API_URL;
  },
  getHeader() {
    return null;
  },
  removeHeader(key) {
    try {
      delete instance.defaults.headers.common[key];
    } catch (error) {
      //
    }
  },
  setHeader() {},
  query(resource, params) {
    return instance.get(resource, params);
  },

  get(resource, slug = "") {
    return instance.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
  post(resource, params) {
    return instance.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return instance.patch(`${resource}/${slug}`, params);
  },
  put(resource, params) {
    return instance.put(`${resource}`, params);
  },

  patch(resource, params) {
    return instance.patch(`${resource}`, params);
  },

  delete(resource) {
    return instance.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },
};

export default ApiService;
export { API_URL };
