const mapSkuQuantities = (order) => {
    const result = {};
    if(!order) {
        return result;
    }
    
    try {
        
        order.items.forEach((item) => {
            result[item.code] = item.quantity;
        });
    } catch(error) {
        console.log("error", error);
    }
    return result;
}

export const transformAPIItems = (data, order) => {
    let result = [];
    try {
        const items = {};
        const mappedSku = mapSkuQuantities(order);

        data.items.forEach((sku) => {
            const key = sku.group_code;
            sku.quantity = mappedSku[sku.sku_code] ? mappedSku[sku.sku_code] : 0;
            if(typeof items[key] != "undefined") {
                items[key].skus.push({ ...sku });
            } else {
                items[key] = { ...sku };
                items[key].selected_sku_code = sku.sku_code;
                items[key].selected_sku = sku;
                items[key].skus = [{ ...sku }]
            }
        });
        result = Object.values(items);
    } catch(error) {
        //
        console.log("error", error);
    }
    
    return result;
}

export const updateItems = (data, order) => {
    console.log("selected sku updating items");
    try {
        const skuQuantities = mapSkuQuantities(order);
        data.forEach((item) => {
            item.selected_sku = null;
            item.skus.forEach((sku) => {
                // item.selected_sku = 
                sku.quantity = skuQuantities[sku.sku_code] ? skuQuantities[sku.sku_code] : 0;
                if(sku.sku_code === item.selected_sku_code) {
                    // console.log('selected sku found');
                    item.selected_sku = sku;
                }
            });
        });
        return data;
    } catch(error) {
        //
        console.log("error", error);
    }
    
    return [];
}