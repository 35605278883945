export default class Customer {
    constructor({ id = null, code = null, type = null, deviceNumber = null, meterNumber = null, accountNumber = null, name = null, mobileNumber = null, email = null, } = {}) {
        this.id = id;
        this.code = code;
        this.type = type;
        this.name = name;
        this.accountNumber = accountNumber;
        this.meterNumber = meterNumber;
        this.deviceNumber = deviceNumber;
        this.mobileNumber = mobileNumber;
        this.email = email;
    }
    isValid() {
        return this.name ? true : false;
    }
}