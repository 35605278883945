export default [
  {
    color: "grey lighten-1",
    currency: "GMD",
    formattedTotal: 'Enter Amount',
    amount: null,
    unit: "KWh",
  },
  {
    color: "blue lighten-1",
    currency: "GMD",
    amount: 35.0,
    unit: "KWh",
  },
  {
    color: "red lighten-1",
    currency: "GMD",
    amount: 50.0,
    unit: "KWh",
  },
  {
    color: "green lighten-1",
    currency: "GMD",
    amount: 75.0,
    unit: "KWh",
  },
  {
    color: "purple lighten-1",
    currency: "GMD",
    amount: 100.0,
    unit: "KWh",
  },
  {
    color: "indigo lighten-1",
    currency: "GMD",
    amount: 150.0,
    unit: "KWh",
  },
  {
    color: "brown lighten-1",
    currency: "GMD",
    amount: 200.0,
    unit: "KWh",
  },
  {
    color: "pink lighten-1",
    currency: "GMD",
    amount: 300.0,
    unit: "KWh",
  },
  {
    color: "deep-purple lighten-1",
    currency: "GMD",
    amount: 400.0,
    unit: "KWh",
  },
  {
    color: "teal lighten-1",
    currency: "GMD",
    amount: 500.0,
    unit: "KWh",
  },
  {
    color: "orange lighten-1",
    currency: "GMD",
    amount: 600.0,
    unit: "KWh",
  },
  {
    color: "blue-grey lighten-1",
    currency: "GMD",
    amount: 1000.0,
    unit: "KWh",
  },
];
