
import JwtService from "@/services/jwt.service";

export default {
  cashpowerDialog: false,
  cashpowerDialogKey: null,
  cashpowerSellDialog: false,
  cashpowerSellDialogKey: false,
  cashpowerParams: null,
  nawecPaymentIntent: null,
  nawecToken: null,
  serverConfig: JwtService.getServerConfig() ? JwtService.getServerConfig() : null,
  recieverNumber: null,
  loading: false,
  resend: false,
  transactionDetail: null,
  exchangeRates: null,
  configSet: false,
}
