import Factory from '@/services/factory';
export default {
  async createAirtimePaymentIntent({ commit, rootState }, data) {
    let result = null;
    commit("mCreatingIntent", true, { root: true });
    console.log("data", data);
    try {
      const payload = {
        ...data
      };

      payload.fromWalletCode = rootState.users.wallet.code;
      payload.public = rootState.users.wallet.public ? true : false;
      payload.userCode = rootState.users.wallet.userCode;
      payload.publisher = rootState.publisher;

      const response = await Factory.get("AirtimeService").createAirtimePaymentIntent(payload);
      if (response && response.data) {
        commit("basket/mClearIntents", {}, {
          root: true,
        });
        commit("mSetTransactionDetail", response.data);
        commit("basket/mAddIntent", response.data, { root: true });
        result = response.data
        // dispatch("confirmAirtimePaymentIntent", payload);
      } else {
        commit("mShowAlert", "Error => Transaction failed ", { root: true });
      }
    } catch (error) {
      let errorMessage = error.response.data.message;
      commit("mShowAlert", "Error => Transaction failed: " + errorMessage, { root: true });
    }
    commit("mCreatingIntent", false, { root: true });
    return result;
  },
  async confirmAirtimePaymentIntent({ commit, rootState }, data) {
    commit("mConfirmingIntent", true, { root: true });
    try {
      const payload = {
        ...data
      };
      if(!payload.fromWalletCode) {
        payload.fromWalletCode = rootState.users.wallet.code;
      }
      
      const response = await Factory.get("AirtimeService").confirmAirtimePaymentIntent(payload);
      if (response && response.data) {
        commit("mShowAlert", "Transaction successfull ", { root: true });
        return response.data;
      } else {
        commit("mShowAlert", "Error => Transaction Failed ", { root: true });
      }
    } catch (error) {
      commit("mShowAlert", "Error => Transaction Failed " + error.message, { root: true });
    }
    commit("mConfirmingIntent", false, { root: true });
  },
}
