// import { Ability } from '@casl/ability';
import JwtService from '@/services/jwt.service';
import ApiService from '@/services/api.service';
import { clearStorage } from "@/services/localstorage.service";
import {
  createWallet,
  setActiveWallet,
  getDefaultWallet,
} from '@/model/wallet.model';

import defineAbilitiesFor from '../../../plugins/ability';

import { getUserModules } from '@/lib/user';
const simpleFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GMD',
}, '#,##0.00');

export default {
  mSetLoginDialog(state, status) {
    state.loginDialog = status;
  },
  mSetSignupDialog(state, status) {
    state.signupDialog = status;
  },
  mSetEditUser(state, user) {
    state.editUser = user ? { ...user } : user;
  },


  mSetAddressDialog(state, status) {
    state.addressDialog = status;
  },
  mSetDetailDialog(state, status) {
    state.detailDialog = status;
  },
  mSetChangePasswordDialog(state, status) {
    state.changePasswordDialog = status;
  },
  mSetQrCodeDialog(state, status) {
    state.qrCodeDialog = status;
  },
  openRiskLevelDialog(state, status) {
    state.riskLevelDialog = status;
  },
  openLockWalletDialog(state, status) {
    state.lockWalletDialog = status;
  },
  mSetMobileDialog(state, dialog) {
    state.mobileDialog = dialog;
  },
  mSetNotificationFormDialog(state, dialog) {
    state.notificationFormDialog = dialog;
  },
  mSetUserProfileDetailDialog(state, status){
    state.userProfileDetailDialog = status
  },
  mSetUserProfileAddressDialog(state, status){
    state.userProfileAddressDialog = status
  },
  mSetCreateWalletDialog(state, status) {
    state.walletDialog = status;
  },

  mSetError(state, error) {
    state.errors = error;
  },
  mSetAuth(state, user) {
    state.isAuthenticated = true;
    state.user = getUserModules(user);
    state.errors = {};
    JwtService.saveUser(state.user);
    JwtService.saveToken(state.user.token);
    ApiService.setHeader();
  },
  mUpdateUser(state, user) {
    const status = user.status ? user.status : null;
    const u = { ...state.user, ...user };
    u.status = status;
    state.user = u;

    console.log("mUpdateUser state.user", state.user)

    JwtService.saveUser(state.user);
    JwtService.saveToken(state.user.token);
  },

  mPurgeAuth(state) {
    this._vm.$log.debug("mPurgeAuth");
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    JwtService.destroyUser();
    // clearStorage();
    ApiService.removeHeader("Authorization");
    // this will reset wallet back to public
    this.commit("users/mSetWallets", null);
  },
  mClearStorage() {
    clearStorage();
    // this will reset wallet back to public
  },
  mSetServerConfig(state, data) {
    state.serverConfig = data;
    JwtService.saveServerConfig(data);
  },
  mSetWallets(state, data) {
    if (!data) {
      this.commit("users/mSetWallet", createWallet());
      return;
    }
    if(data && !data.length) {
      this.commit("users/mSetWallet", createWallet());
      return;
    }
    
    const wallets = data;
    this._vm.$log.debug("mSetWallets");
    this._vm.$log.debug("wallets", wallets);
    state.wallets = wallets
      .sort((a, b) => (a.createdAt > b.createdAt) ? 1 : ((b.createdAt > a.createdAt) ? -1 : 0))
      .map((item) => {
        item.prettyValue = item.name + ' ' + simpleFormatter.format(item.currentBalance);
        return item;
      });
    // state.wallet = 
    this.commit("users/mSetDefaultWallet");
    JwtService.saveWallets(wallets);
  },
  mSetDefaultWallet(state) {
    // this._vm.$log.debug("mSetDefaultWallet");
    const wallets = state.wallets;
    if(wallets && wallets.length) {
      let wallet = getDefaultWallet(wallets);
      if(state.wallet) {
        const iWallet = wallets.find((item) => item.code === state.wallet.code);
        if(iWallet) {
          wallet = iWallet;
        }
        // state.wallet = wallet;
      }
      // this._vm.$log.debug("getDefaultWallet", wallets);
      // const defaultWallet = getDefaultWallet(wallets);
      // this._vm.$log.debug("defaultWallet", defaultWallet);
      // console.log("Essa defaultWallet", defaultWallet);
      // console.log("Essa state.wallet", state.wallet);
      
      
      // const wallet = state.wallet.public && defaultWallet ? defaultWallet :  state.wallet;
      this.commit("users/mSetWallet", wallet);
    }
  },
  mSetWallet(state, wallet) {
    // this._vm.$log.debug("mSetWallet", wallet);
    state.wallet = wallet;
    state.accessRights = defineAbilitiesFor(state.user, wallet);
    state.ability = defineAbilitiesFor(state.user, wallet);
    setActiveWallet(wallet);
  },
  mLoadWalletDiolog(state, params) {
    // console.log('wallet dialog loaded')
    // JwtService.saveWallet = wallet;
    state.walletParams = params
    state.walletDialog = true;
    state.walletDialogKey = new Date().toLocaleString();
  },
  mSetListWalletDialog(state, value) {
    state.listWalletDialog = value;
  },
  mUpdateWalletDialog(state, value) {
    state.walletDialog = value;
  },
  mcloseWalletDialog(state, wallet) {
    JwtService.saveWallet(wallet);
    state.walletDialog = false;
  },
  mSetUser(state, value){
    state.selectedUser = value
  },
  mSetSubAgents(state, value){
    state.subAgents = value
  }
  // mUpdateTransactions(state, data) {
  //   if (!data) {
  //     return;
  //   }
  //   const rows = data.map(item => {
  //     const option = options[item.transaction_type] ? options[item.transaction_type] : options['DEFAULT'];

  //     return {
  //       icon: String.fromCharCode(option.icon),
  //       class: option.class,
  //       amount: item.amount,
  //       absAmount: Math.abs(item.amount),
  //       pretty_amount: formatter.format(item.amount),
  //       status: 'completed',
  //       description: item.description ? item.description : '',
  //       category: item.transaction_type,
  //       name: item.transaction_type,
  //       itemType: item.transaction_type,
  //       date: new Date(item.dt),
  //       pretty_date: dateFormat.format(new Date(item.dt)),
  //       chartLabel: option.chartLabel,
  //       color: option.color,
  //       secret: item.secret,
  //       id: item.id,
  //     };
  //   });

  //   let results = [];
  //   let cache = {};

  //   const chartGroups = {
  //     "NAWEC-PREPAY": "ELECTRICITY",
  //   };

  //   const pieChartData = creatPieChartTotals(rows, 'chartLabel', 'chartLabel', 'absAmount');
  //   // console.log('pieChartData', pieChartData);

  //   for (let i = 0; i < rows.length; i++) {
  //     const row = rows[i];
  //     if (!cache[row.date]) {
  //       results.push({
  //         itemType: 'header',
  //         date: new Date(row.date),
  //         pretty_date: row.pretty_date,

  //       });
  //       cache[row.date] = true;
  //     }
  //     results.push(row);
  //   }
  //   state.transactionDisplay = results;
  //   state.pieChartData = pieChartData;
  //   JwtService.saveObject('_transactionDisplay', state.transactionDisplay);
  //   JwtService.saveObject('_pieChartData', state.pieChartData);
  // },
}
