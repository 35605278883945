// import ApiService from "./api.service";
import PublicApiService from "./public-api.service";

const ConfigService = {
  getConfig() {
    return PublicApiService.query("/p/pay/config");
  },
}

export default ConfigService;
