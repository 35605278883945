import MoneyTransferService from "@/services/money-transfer.service";
export default {
  async aCreatePaymentIntent({ commit }, data) {
    commit("mCreatingIntent", true, {
      root: true,
    });
    try {
      const payload = {
        ...data,
      };

      const result = await MoneyTransferService.createPaymentIntent(payload);
      if (result && result.status) {
        // commit("mSetTransactionDetail", result.data);
        
        commit("basket/mClearIntents", {}, {
          root: true,
        });
        commit("basket/mAddIntent", result.data, {
          root: true,
        });
        // dispatch("confirmAirtimePaymentIntent", payload);
      } else {
        commit("mShowAlert", "Error => Transaction failed ", {
          root: true,
        });
      }
    } catch (error) {
      commit("mShowAlert", "Error => Transaction failed " + error.message, {
        root: true,
      });
      throw error.message;
    }
    commit("mCreatingIntent", false, {
      root: true,
    });
  },
  async aConfirm({ commit }, data) {
    commit("mConfirmingIntent", true, {
      root: true,
    });
    try {
      const payload = {
        ...data,
      };
      const result = await MoneyTransferService.confirmNawecPaymentIntent(payload);
      if (result && result.status) {
        commit("mShowAlert", "Transaction successfull ", {
          root: true,
        });

        return result;
      } else {
        commit("mShowAlert", "Error => Transaction Failed ", {
          root: true,
        });
      }
    } catch (error) {
      commit("mShowAlert", "Error => Transaction Failed " + error.message, {
        root: true,
      });
    }
    commit("mConfirmingIntent", false, {
      root: true,
    });
  },
};
