export default {
  mBuyGiftCard(state, params) {
    state.giftCardParams = params ? { ...params } : {};
    state.giftCardDialog = true;
    state.giftCardDialogKey = new Date().toLocaleString();
  },
  mSellGiftCard(state, params) {
    state.giftCardParams = params ? { ...params } : {};
    state.giftCardSellDialog = true;
    state.giftCardSellDialogKey = new Date().toLocaleString();
  },
  mUpdateGiftCardDialog(state, value) {
    state.giftCardDialog = value;
  },
  mUpdateGiftCardSellDialog(state, value) {
    state.giftCardSellDialog = value;
  },
  mResetParams(state) {
    state.giftCardParams = null;
  },
  mSetGiftCards(state, giftCards) {
    // console.log("giftCards", giftCards);
    state.giftCards = giftCards;
  },
  mSetGiftCard(state, giftCard) {
    state.giftCard = giftCard;
  },

}
