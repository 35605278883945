import PublicApiService from "./public-api.service";

const IdentityService = {
  getUser(secret) {
    return PublicApiService.query(`/v1/identity/${secret}`);
  },
  getDocuments(code) {
    return PublicApiService.query(`/v1/documents/${code}`);
  },
  saveUser(payload) {
    return PublicApiService.post(`/v1/identity`, payload);
  },
  saveDocuments(payload) {
    return PublicApiService.post(`/v1/identity/documents`, payload);
  },
  saveDocument(payload) {
    return PublicApiService.post(`/v1/identity/document`, payload);
  },
}



export default IdentityService;
