import ApiService from "./api.service";

const MoneyTransferService = {
  createPaymentIntent(payload) {
    return ApiService.post("/wallet/payout/create-payment-intent", payload);
  },
  confirm(payload) {
    return ApiService.post("allet/payout/confirm", payload);
  },
  
}

export default MoneyTransferService;