import { IdentityUserDocument } from "./model/document";

const getItemsRequested = (user) => {
  const itemsRequestedArray = user.itemsRequested.split(",");
  itemsRequestedArray.push("complete");
  return itemsRequestedArray;
};

export default {
  mSetLoading(state, value) {
    state.loading = value;
  },
  mSetUser(state, user) {
    state.user = user;
    console.log("state.user", state.user);
    this.commit("identity/mSetStages", user);
  },
  mSetStages(state, user) {

    const progress = { ...state.progress };
    const itemsRequestedArray = getItemsRequested(user);

    progress.stages = progress.allStages.filter((item) => itemsRequestedArray.includes(item.key));
    
    state.progress = { ...progress };

    console.log("progress.stages", progress.stages);
  },
  mSetStage(state, params) {
    const user = state.user;
    const itemsRequestedArray = getItemsRequested(user);
    const stage = params.stage ? params.stage : "intro";
    const progress = { ...state.progress };
    progress.stage = stage;

    progress.stageTotal = itemsRequestedArray.length;

    if(stage === 'intro') {
      progress.percentComplete = 0;
      progress.stageTotal = 0;
    } else {
      const index = itemsRequestedArray.indexOf(stage);
      progress.percentComplete = Math.round(((index+1)/itemsRequestedArray.length)*100);
    }
    state.progress = progress;
  },
  mSetNextStage(state, params) {
    const user = state.user;
    const itemsRequestedArray = getItemsRequested(user);

    const progress = state.progress;

    const currentStage = progress.stage ? progress.stage : "intro";
    const lParams = { ...params };

    const stage = params && params.stage ? params.stage : currentStage;

    

    if(stage === 'intro') {
      lParams.stage = itemsRequestedArray[0];

    } else {
      const index = itemsRequestedArray.indexOf(stage);
      lParams.stage = itemsRequestedArray[index+1];
    }
    this.commit("identity/mSetStage", lParams);
  },
  mOnlySetDocument(state, data) {
    const item = state.documents.find((item) => item.type === data.type);
    const document = item ? item : new IdentityUserDocument();
    document.loadFromObject(data);
    state.document = document;
  },
  mSetDocument(state, data) {
    this.commit("identity/mUpsertDocument", data);
  },
  mAddDocument(state, data) {
    this.commit("identity/mUpsertDocument", data);
  },
  mUpsertDocument(state, data) {

    this.commit("identity/mOnlySetDocument", data);

    const document = state.document;
    const documents = state.documents.filter((item) => item.type != document.type);
    documents.push(document);
    state.documents = documents;

    console.log("mUpsertDocument", documents);
  },
  mSetSaving(state, value) {
    state.saving = value;
  }
}
