import { saveInStorage } from "../../../services/localstorage.service";
import { COMMERCE_CURRENCY, DEFAULT_COMMERCE_CURRENCY_CODE, COMMERCE_ORDER, COMMERCE_FILTERED_PARAMS } from "./const";
import { transformAPIItems, updateItems } from "./lib/transformer.lib";

export default {
  mSetLoading(state, value) {
    state.loading = value;
  },
  mSetSaving(state, value) {
    state.saving = value;
  },
  mSetIntent(state, intent) {
    state.intent = intent;
  },
  mSetDefaultCurrencyCode(state, value) {
    if (!state.currency) {
      // console.log("value", value);
      // alert(value);
      state.currency = value ? value : DEFAULT_COMMERCE_CURRENCY_CODE;
      saveInStorage(COMMERCE_CURRENCY, state.currency);
    }
    // COMMERCE_CURRENCY
  },
  mSetFilteredParams(state, params) {
    if (params) {
      // console.log("value", value);
      // alert(value);
      state.filteredParams = params ? params : {};
      saveInStorage(COMMERCE_FILTERED_PARAMS, params);
    }
    // COMMERCE_CURRENCY
  },
  mSetTicketDetails(state, value){
    state.ticketDetails = value
  },
  mSetEventDetails(state, value){
    state.eventDetails = value
  },
  mSetCurrencyCode(state, currency) {
    state.currency = currency;
    saveInStorage(COMMERCE_CURRENCY, currency);

    // COMMERCE_CURRENCY
  },
  mSetOrder(state, order) {
    state.order = order;
    // console.log("ORderss", order)
    saveInStorage(COMMERCE_ORDER, order);

    // COMMERCE_CURRENCY
  },
  mUpdateOrder(state, currency) {
    //const updateOrder = state.order

    state.order.currency = currency
    const items = state.order.items
      for(const item of items){
        item.sku.currency = currency
      }
    console.log("Update ORderss", state.order)
    saveInStorage(COMMERCE_ORDER, state.order);
    console.log("state order", state.order)

    // COMMERCE_CURRENCY
  },
  mSetItemResponse(state, data) {
    // console.log("mSetItems", items);
    try {

      const items = transformAPIItems(data, state.order);
      this.commit("commerce/mSetItems", items);
    } catch(error) {
      console.log("mSetItemResponse error", error)
    }
    
  },
  mSetItems(state, items) {
    // alert("here");
    // console.log("mSetItems", items);
    state.items = items;
  },
  mUpdateItems(state) {
    // alert("hey");
    const items = updateItems(state.items, state.order);
    // console.log("1 mUpdateItems 101 ", items);
    // console.log("2 mUpdateItems 102 order", state.order);
    if(state.order) {
      state.order.currency = state.currency;
    }
    state.items = items;
  },
  mSetCategory(state, categories) {
    state.categories = categories;
  },
  mUpdateTicketDialog(state, value) {
    state.ticketDialog = value;
  },
  mBuyTicket(state, params) {
    state.ticketParams = params ? { ...params } : {};
    state.ticketDialog = true;
    state.ticketDialogKey = new Date().toLocaleString();
  },
  mSetAmount(state, value){
    state.price = value
  },
  mReset() {
    this.commit("commerce/mSetOrder", null);
    this.commit("commerce/mUpdateItems");

  }
};
