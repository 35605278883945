export default{
    transactionType:null,
    code:null,
    transactions:null,
    loading: null,
    transactionsDialog: false,
    transactionsParam: null,
    transactionsDialogKey: null,
    payouts:null,
    type: 'DEFAULT',
    pendingTransactions:null,
}