import ar from './ar-AR';
import de from './de-DE';
import en from './en-US';
import id from './id-ID';
import pt from './pt-PT';
import languages from './languages';

const messages = {
    ar,
    de,
    en,
    id,
    pt,
}

export {
    languages,
    messages,
};
