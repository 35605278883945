import Base from "./base";
class IdentityUserDocument extends Base {
  constructor() {
    super();
    this.id = null;
    this.code = null;
    this.name = null;
    this.userCode = null;
    this.type = null;
    this.status = null;
    this.requested_by = null;
    this.fileName = null;
    this.fileSize = null;
    this.fileType = null;
    this.fileUrl = null;
    this.fileExtension = null;
    this.fileContent = null;
    this.loggedInUserCode = null;
    this.loggedInUserFullname = null;
    this.fileOCRResult = null;
    this.fileOCRStatus = null;
    this.fileEntitiesStatus = null;
    this.fileEntitiesResult = null;
    this.statusRequestCount = null;
    this.statusCheckCount = null;
    this.lastStatusCheck = null;
    this.createdDate = null;
    this.updatedDate = null;
    this.createdAt = null;
    this.updatedAt = null;
  }
}

class IdentityUserDocuments {
  constructor() {
    this.documents = [];
  }

  getDocument(type) {
    return this.documents.find((item) => item.type === type);
  }

  addDocument(type) {
    const document = new IdentityUserDocument();
    document.type = type;
    this.documents = this.documents.filter((item) => item.type !== type).push(document);
    return document;
  }

  loadDocumentFromLocalStorage() {
    
  }
}

export { IdentityUserDocument, IdentityUserDocuments };
