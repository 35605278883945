import numeral from './numeral';
import Item from './item';

export default class Basket {
    constructor({ id = null, items = [], customer = null, secret = null, } = {}) {
        this.id = id;
        this.total = 0.00;
        this.items = items;
        this.customer = customer;
        this.activeItem = null;
        this.status = null;
        this.valid = false;
        this.serviceFee = 0.00;
        this.secret = secret;
    }
    validate() {
        return this.isValid();
    }
    isValid() {
        const total = this.getTotal();
        const item = this.getLastAddedItem();
        const customer = item && item.customer ? item.customer : null;
        this.valid = total > 0 && customer && customer.isValid() ? true : false;
        return this.valid;
    }
    setCustomer(customer) {
        this.customer = customer;
    }
    setItems(items) {
        this.items = [...items];
    }
    setServiceFee(serviceFee) {
        this.serviceFee = serviceFee;
    }
    getServiceFee() {
        return this.serviceFee;
    }
    setSecret(secret) {
        this.secret = secret;
    }
    getSecret() {
        return this.secret;
    }
    addItem(item) {
        const iItem = new Item({...item});
        const amount = numeral(iItem.amount);
        if (!isNaN(amount.value())) {
            this.items.push(iItem);
        } else {
            throw 'Invalid number or quantity';
        }
    }
    calculateTotal() {
        let total = numeral(0);
        this.items.forEach((item) => {
            // console.log('item.getTotal()', item.getTotal());
            total = total.add(item.getTotal());
        });
        this.total = total.value();
        
    }
    getTotal() {
        this.calculateTotal();
        return this.total;
    }
    getFormatedTotal() {
        this.calculateTotal();
        return numeral(this.total).format('$0,0.00');
    }
    getLastAddedItem() {
        const items = this.items;
        if(items && items.length) {
            return items[items.length-1];
        }
        return null;
    }
}