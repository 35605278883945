export default class Base {
  constructor() {}

  loadFromJson(jsonString) {
    const parsedObject = JSON.parse(jsonString);
    Object.assign(this, parsedObject);
  }

  loadFromObject(object) {
    Object.assign(this, object);
  }


  toJson() {
    const { ...jsonObj } = this;
    return JSON.stringify(jsonObj);
  }

  toKeyValueObject() {
    const { ...keyValueObj } = this;
    return keyValueObj;
  }

  toObject() {
   
    return this.toKeyValueObject();
  }
}
