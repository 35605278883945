import { CASHPOWER_SERVICE_CODE } from "../../../const/services"
export default {
  mSetTransactionDetail(state, transactionDetail) {
    state.transactionDetail = null;
    state.transactionDetail = { ...transactionDetail };
  },
  mBuyCashpower(state, params ) {
    const payload = {
      name: null,
      meterNo: null,
      amount: null,
      mobile: null,
      secret: null,
    };
    // console.log('buy cashpower', params)
    state.cashpowerParams = null;
    state.cashpowerParams = params ? { ...params } : payload;
    state.cashpowerDialog = true;
    state.cashpowerDialogKey = new Date().toLocaleString();
  },
  mSellCashpower(state, params ) {
    const payload = {
      name: null,
      meterNo: null,
      amount: null,
      mobile: null,
      secret: null,
    };
    // console.log('buy cashpower', params)
    state.cashpowerParams = null;
    state.cashpowerParams = params ? { ...params } : payload;
    state.cashpowerSellDialog = true;
    state.cashpowerSellDialogKey = new Date().toLocaleString();
  },
  mLoading(state, value) {
    state.loading = value;
  },
  mUpdateBuyCashpowerDialog(state, value) {
    state.cashpowerDialog = value;
  },
  mUpdateSellCashpowerDialog(state, value) {
    state.cashpowerSellDialog = value;
  },
  mSetNawecPaymentIntent(state, data) {
    state.nawecPaymentIntent = null;
    state.nawecPaymentIntent = data;
  },
  mUpdateNawecToken(state, data){
    state.nawecToken = null;
    state.nawecToken = data;
  },
  mSetReciepientNumber(state, data){
    state.recieverNumber = null;
    state.recieverNumber = data;
  },
  mSetResend(state, value){
    state.resend = false;
    state.resend = value;
  },
  mResetParams(state){
    state.cashpowerParams = null;
  },
  mSetConfig(state, config) {
    const { exchangeRates } = config;
    state.exchangeRates = exchangeRates.filter((item) => item.serviceCode === CASHPOWER_SERVICE_CODE);
    state.configSet = true;
  },
}
