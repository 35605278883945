import { createBundles } from './lib/exchange-rate';
export default {
    mUpdateBasket(state, basket) {
        state.total = basket.getTotal();
        state.formattedTotal = basket.getFormatedTotal();
        state.valid = basket.isValid();
        state.lastAddedItem = basket.getLastAddedItem();
        // console.log('state.valid', state.valid);
        // console.log('state.lastAddedItem,', state.lastAddedItem);
        // alert(basket.getTotal());
    },
    mSetActiveItem(state, item) {
        state.activeItem = item;
        state.secret = null;
        // console.log('active Item ', state.activeItem)
    },
    mUpdateProcessState(state, processing) {
        state.processing = processing;
    },
    mShowReceipt(state, showReceipt) {
        state.showReceipt = showReceipt;
        // console.log('show' , state.showReceipt)
    },
    mSetFormValid(state, valid) {
        state.formValid = valid;
        // alert(valid);
    },
    mSetStatus(state, status) {
        state.status = status;
        // alert(valid);
    },
    mSetSecret(state, secret) {
        state.secret = secret;
        // alert(valid);
    },
    mSetConfirmedItem(state, confirmedItem) {
        state.confirmedItem = confirmedItem;
        // alert(valid);
    },
    mSetSummary(state, summary) {
        state.summary = summary;
        // alert(valid);
    },
    mSetNumber(state, number) {
        state.number = number

    },
    mSetOperator(state, operator) {
        state.operator = operator

    },
    mAddSecret(state, secret) {
        const secrets = [ ...state.secret ];
        secrets.push(secret);
        state.secrets = secrets;
    },
    mDeleteSecret(state, secret) {
        const secrets = [ ...state.secret ].filter((item) => item !== secret);
        state.secrets = secrets;
    },
    mClearSecrets(state) {
        state.secrets = [];
    },
    mAddIntent(state, intent) {
        const intents = [ ...state.intents.filter((item) => item.secret !== intent.secret) ];
        intents.push(intent);
        state.intents = intents;
    },
    mDeleteIntent(state, intent) {
        const intents = [ ...state.secret ].filter((item) => item.id !== intent.id);
        state.intents = intents;
    },
    mClearIntents(state) {
        state.intents = [];
    },
    mResetBundles(state) {
        state.bundles = [];
    },
    mSetBundles(state, serviceCode) {
        const amountsConfig = {};
        amountsConfig['DEFAULT'] = [
            500,
            1000,
            1500,
            2000,
            2500,
            3000,
        ];
        amountsConfig['TOPUP'] = [
            1000,
            1500,
            2000,
            3000,
            10000,
            15000,
            20000,
        ];

        const amounts = serviceCode && amountsConfig[serviceCode] ? amountsConfig[serviceCode] : amountsConfig['DEFAULT'];
        // console.log("mSetBundles state.exchangeRates",  state.exchangeRates);
        // console.log("mSetBundles serviceCode", serviceCode);
        state.bundles = createBundles(serviceCode, state.fromCurrencyCode, state.toCurrencyCode, amounts, state.exchangeRates);
    },
    mSetProductBundles(state, { serviceCode, amounts }) {
        state.bundles = createBundles(serviceCode, state.fromCurrencyCode, state.toCurrencyCode, amounts, state.exchangeRates);
    },
    mSetFromCurrencyCode(state, { fromCurrencyCode, serviceCode }) {
        if(!fromCurrencyCode) {
            return;
        }
        state.fromCurrencyCode = fromCurrencyCode;

        if(serviceCode) {
            this.commit("basket/mSetBundles", serviceCode);
        }
        
    },
    mSetDialCode(state, { dialCode }) {
        state.dialCode = dialCode;
    },
    mSetToCurrencyCode(state, toCurrencyCode) {
        state.toCurrencyCode = toCurrencyCode;
    },
    mSetConfig(state, { exchangeRates }) {
        state.exchangeRates = exchangeRates;
    }
}