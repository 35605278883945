import PublicApiService from "./public-api.service";

const Service = {
  getItems(params) {
    
    return PublicApiService.query(`/v1/commerce/catalogue/items`, { params });
  },
  getCategories() {
    return PublicApiService.query(`/v1/commerce/catalogue/categories`);
  },
  addItem(data) {
    return PublicApiService.post(`/v1/commerce/basket/item`, data);
  },
  changeCurrency(data) {
    return PublicApiService.post(`/v1/commerce/basket/change-currency`, data);
  },
  getOrder(orderCode) {
    return PublicApiService.query(`/v1/commerce/basket/${orderCode}`);
  },

}



export default Service;
