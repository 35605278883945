export function getUserModules(pUser) {
    let user = { ...pUser };
    user.userModules = [];
    if (typeof user.modules == "undefined" || !user.modules || !user.modules.length) {
        return user;
    }
    try {
        if (user.config && user.config.modules && user.config.modules.length) {
            user.userModules = user.modules.filter(item => user.config.modules.includes(item.code));
        }
    } catch (error) {
        console.log("getACLCharts", error);
    }
    return user;
}