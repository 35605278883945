import { getFromStorage } from "../../../services/localstorage.service";
import {
  COMMERCE_CURRENCY,
  COMMERCE_ORDER,
  COMMERCE_FILTERED_PARAMS,
} from "./const";
const currency = getFromStorage(COMMERCE_CURRENCY);
const order = getFromStorage(COMMERCE_ORDER);
const filteredParams = getFromStorage(COMMERCE_FILTERED_PARAMS);

const orderCode = order ? order.code : null;

export default {
  intent: null,
  loading: false,
  saving: false,
  currency,
  items: [],
  categories: [],
  item: null,
  price: null,
  ticketParams: null,
  ticketDialog: false,
  ticketDialogKey: null,
  status: null,
  orderCode,
  activeTickets:[],
  scannedTickets:[],
  myTickets:[],
  ticketDetails:"",
  eventDetails:"",
  order,
  filteredParams: filteredParams ? filteredParams : {},

  productCategories: [
    { code: "provisions", value: "Provisions" },
    { code: "fashion", value: "Fashion Wear" },
    { code: "pastery", value: "Pastery" },
    { code: "electronics", value: "Electronics" },
  ],

  ndugaItems: [
    {
      id: 1,
      code: "cooking_oil",
      categoryCode:"provisions",
      name: "Cooking Oil",
      desc: "High quality 20 ltr vegetable cooking oil.",
      img: "@/tenant/assets/images/nduga-images/bag-of-rice.jpg",
      price:"GMD 2000.00"
    },
    {
      id: 2,
      code: "bag_of_rice",
      name: "Cooking Oil",
      desc: "A 50kg bag of Basmati rice, 100% broken.",
      img: "@/tenant/assets/images/nduga-images/bag-of-rice.jpg",
      price:"GMD 1750.00"
    },
  ],
};
