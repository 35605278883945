import UsersService from "@/services/users.service";
import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import walletTransformer from "../../../lib/transformer/wallets-transformers";
// import WebSocketClient from "@/lib/websocket-client";
// import {API_URL} from '@/services/api.service';
// const webSocketClient = new WebSocketClient(API_URL + "/ws");

export default {
  // eslint-disable-next-line no-empty-pattern
  aSignUp({ rootState }, payload) {
    try {
      const geo = rootState.geo;
      payload.regCity = geo.city;
      payload.regIp = geo.ip;
      payload.regRegion = geo.region;
      payload.regCountry = geo.country;
      payload.regLoc = geo.loc;
    } catch (error) {
      //
    }
    return new Promise((resolve, reject) => {
      UsersService.webSignUp(payload)
        .then(function() {
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },
  aSignIn({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      UsersService.signIn(payload)
        .then(function(response) {
          // console.log('response', response);
          commit("mSetAuth", response.data);

          dispatch("aInit");
          resolve(response);
        })
        .catch(function(err) {
          // alert(err);
          reject(err);
        });
    });
  },
  async aUpdateUser({ commit }) {
    try {
      const response = await UsersService.status({});
      const data = response.data;
      commit("mUpdateUser", data);
      
    } catch (error) {
      //
    }
  },
  aSignInUsingToken({ commit, dispatch }, token) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader(token);
      UsersService.signInUsingToken()
        .then(function(response) {
          // console.log('response', response);
          commit("mSetAuth", response.data);

          dispatch("aInit");
          resolve(response);
        })
        .catch(function(err) {
          // alert(err);
          reject(err);
        });
    });
  },

  aChangePassword({ state, dispatch }, payload) {
    state.loading = false;

    return new Promise((resolve, reject) => {
      UsersService.changePassword(payload)
        .then(function() {
          dispatch("aUpdateUser");
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },
  async aUpdateEditUser({ commit }, code) {
    this.loading = true;
    try {
      
      const response = await UsersService.getDetail(code);
      const data = response.data;
      commit("mSetEditUser", data)
      
     
    } catch (error) {
      //
    }
    this.loading = false;
  },


  aChangeUserPassword({state,dispatch}, payload){
    state.loading = false;
    return new Promise((resolve, reject)=>{
      UsersService.changeUserPassword(payload)
      .then(function(){
        dispatch("aUpdateUser");
        console.log("Update User Password")
        resolve();
      })
      .catc(function(err){
        reject(err)
      })
    })
  },
/*   aUpdateAddress({state,dispatch}, payload){
    state.loading = false;
    return new Promise((resolve, reject)=>{
      UsersService.updateUserAddress(payload)
      .then(function(){
        dispatch("aUpdateUser");
        console.log("User address updated")
        resolve();
      })
      .catc(function(err){
        reject(err)
      })
    })
  }, */
  aUpdateAddress({ state, dispatch }, payload) {
    state.loading = false;
    return new Promise((resolve, reject) => {
      UsersService.updateUserAddress(payload)
        .then(function() {
          dispatch("aUpdateUser");
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    
  });  
  },
  aUpdateDetails({ state,dispatch }, payload) {
    state.loading = false;
    return new Promise((resolve, reject) => {
      UsersService.updateUserDetails(payload)
        .then(function() {
          dispatch("aUpdateUser");
          console.log("user details updated")
          resolve();
        })
        .catch(function(err) {
          reject(err);
        });
    });
  },

  async aLogout({ commit }) {
    commit("mPurgeAuth");
  },
  aSubscribe({ state }) {
    const user = state;
    // console.log("user", user);
    if (user) {
      // webSocketClient.connect();
      // webSocketClient.subscribe(payload.id, '/user/' + user.user.id + payload.endpoint, payload.onMessageReceived, typeof payload.renew != "undefined" ? payload.renew : false);
    }
    // (subscriberId, endpoint, onMessageReceived, renew = false)

    //const webSocketClient = state.webSocketClient;
    // commit('mPurgeAuth');
  },
  aCheckAuth({ commit }) {
    this._vm.$log.debug("aCheckAuth");
    if (JwtService.getToken()) {
      ApiService.setHeader();
    } else {
      this._vm.$log.debug("aCheckAuth", "mPurgeAuth");
      commit("mPurgeAuth");
    }
  },
  async aInit({ dispatch, commit }) {
    this._vm.$log.debug("aInit");
    dispatch("aSaveServerConfig");
    if (JwtService.getToken()) {
      dispatch("aGetWalletList");
    } else {
      commit("mSetWallets", null);
    }
  },
  async aSaveServerConfig({ commit }) {
    this._vm.$log.debug("aSaveServerConfig");
    try {
      const response = await UsersService.getServerConfig();
      if (response && response.data) {
        commit("mSetServerConfig", response.data);
      }
      return response;
    } catch (error) {
      this._vm.$log.error("aSaveServerConfigggg", error);
    }
  },
  async aGetWalletList({ commit }) {
    // console.log('load wallets')
    this._vm.$log.debug("aGetWalletList");
    try {
      const result = await UsersService.getWallets();
      if (result.data) {
        const data = walletTransformer.transformList(result.data);
        // const { data}
        // console.log('result', result.data);
        commit("mSetWallets", data);
        return data;
      } else {
        this._vm.$log.debug("No data from API");
        return null;
      }
    } catch (error) {
      this._vm.$log.debug("API error", error);
    }
  },
  async aGetWalletsUsingCode(state) {
    state.loading = true
    const code = this.$route.params.code
    ? this.$route.params.code
    : this.loggedInUser.code;
    // console.log('load wallets')
    this._vm.$log.debug("aGetWalletsUsingCode");
    try {
      const result = await UsersService.getWalletsUsingCode(code);
      if (result.data) {
        const data = walletTransformer.transformList(result.data);
        alert("my wallets", data)
        return data;
      } else {
        this._vm.$log.debug("No data from API");
        return null;
      }
    } catch (error) {
      this._vm.$log.debug("API error", error);

    }
    state.loading=false
  },

  async aGetSubAgents({ state, commit }){
    state.loading = false
    await UsersService.getSubAgents()
    .then((response)=>{
      commit("mSetSubAgents", response.data)
    })
    .catch((error)=>{
      commit("mSetSubAgents", [])
      console.log(error)
    })

  }
};
