import { IdentityUser } from "./model/user";
// import { IdentityUserDocument } from "./model/document";

const allStages = [
  {
    key: "country",
    title: "Select Country Of Residence",
    description: "Enter your country of residence",
    icon: "mdi-camera-front",
  },
  {
    key: "name",
    title: "Personal Details",
    description: "Enter your personal details",
    icon: "mdi-camera-front",
  },
  {
    key: "address",
    title: "Address",
    description: "Enter your address",
    icon: "mdi-camera-front",
  },
  {
    key: "identification",
    title: "ID Documents",
    description: "Upload you identity documents",
    icon: "mdi-camera-front",
  },
  {
    key: "selfie",
    title: "Selfie",
    description: "Take a selfie to speed up approval",
    icon: "mdi-camera-front",
  },
  {
    key: "document",
    title: "Upload Documents",
    description: "Upload Requested Documents",
    icon: "mdi-camera-front",
  },
  {
    key: "complete",
    title: "Complete",
    description: "Complete submission",
    icon: "mdi-camera-front",
  },
];

export default {
  user: new IdentityUser(),
  documents: [],
  loading: false,
  saving: false,
  progress: {
    display: true,
    stage: null,
    stages: [],
    currentPage: null,
    allStages,
    nextPage: null,
    percentComplete: null,
    stepValue: null,
    totalSteps: null,
  },
};
