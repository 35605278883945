import Vue from "vue";
import Router from "vue-router";

import { store } from "./store/store";

import defaultRoute from "@tenant/default-route.js";

Vue.use(Router);

const posRoutes = [
  {
    path: "/",
    meta: {
      requiresAuth: true,
    },
    name: "home",
    component: () => import("./views/pos/index"),
  },
  {
    name: "POSTransactions",
    path: "/transactions",
    component: () => import("./views/pos/transactions"),
  },
];
const adminRoutes = [
  {
    path: "/",
    name: "admin-index",
    redirect: "/admin/dashboard",
  },
  {
    path: "cashpower",
    name: "admin-cashpower",
    component: () => import("./views/admin/admin-cashpower"),
  },
  {
    path: "documents/upload-document",
    name: "upload-document",
    component: () => import("./views/admin/admin-upload-document"),
  },
  {
    path: "documents/upload-document/:code",
    name: "upload-document-code",
    component: () => import("./views/admin/admin-upload-document"),
  },
  {
    path: "generate-qrcode",
    name: "generate-qrcode",
    component: () => import("./views/admin/generate-qr-code"),
  },
  {
    path: "receipt/receipt-upload",
    name: "receipt-upload",
    component: () => import("./views/admin/receipt-upload"),
  },
  {
    path: "report/pps-reports",
    name: "pps-reports",
    component: () => import("./views/admin/report/pps-reports"),
  },
  
  {
    path: "health-summary",
    name: "health-summary",
    component: () => import("./views/admin/innovarx-admin/health-summary.vue"),
  },
/*   {
    path: "encounter-report",
    name: "encounter-report",
    component: () => import("@tenant/views/innovarx-admin/encounter-report/list-reports"),
  }, */
  {
    path: "innovarx-dashboard",
    name: "innovarx-dashboard",
    component: () => import("./views/admin/innovarx-admin/innovarx-dashboard.vue"),
  },
  {
    path: "patients",
    name: "patients",
    component: () => import("./views/admin/innovarx-admin/patients.vue"),
  },
  {
    path: "patient-details",
    name: "patient-details",
    component: () => import("./views/admin/innovarx-admin/patient-details.vue"),
  },
  {
    path: "medication",
    name: "medication",
    component: () => import("./views/admin/innovarx-admin/medication.vue"),
  },
  {
    path: "orders",
    name: "orders",
    component: () => import("./views/admin/innovarx-admin/orders.vue"),
  },
  {
    path: "reminder",
    name: "reminder",
    component: () => import("./views/admin/innovarx-admin/reminder.vue"),
  },
  {
    path: "circles",
    name: "admin-circles",
    component: () => import("./views/admin/circles.vue"),
  },
  {
    path: "products",
    name: "products",
    component: () => import("./views/admin/innovarx-admin/products.vue"),
  },
/*   {
    path:"products/giftcard/coupons",
    name:"giftcards",
    component:()=> import("./views/admin/buy-giftcard")
  }, */
  {
    path: "invoice",
    name: "invoice",
    component: () => import("./views/admin/invoice"),
  },
  /*   {
    path: 'invoice/invoice-preview',
    name: 'invoice-preview',
    component: () => import('./views/admin/invoice-preview')
  }, */

  {
    path: "ticketo/dashboard",
    name: "ticketo-dashboard",
    component: () => import("./views/admin/ticketo-admin/ticketo-dashboard.vue"),
  },
  {
    path: "ticketo/all-events",
    name: "all-events",
    component: () => import("./views/admin/ticketo-admin/all-events.vue"),
  },
  {
    path: "ticketo/transport/routes",
    name: "ticketo-transport-routes",
    component: () => import("./views/admin/ticketo/transport/reports/routes.vue"),
  },
  {
    path: "ticketo/transport/summary",
    name: "ticketo-transport-summary",
    component: () => import("./views/admin/ticketo/transport/reports/summary.vue"),
  },
  {
    path: "ticketo/transport/daily-traffic",
    name: "ticketo-transport-daily-traffic",
    component: () => import("./views/admin/ticketo/transport/reports/daily-traffic.vue"),
  },
  {
    path: "ticketo/ticket-details/:code",
    name: "ticket-details",
    props:true,
    component: () => import("./views/admin/ticketo-admin/ticket-details.vue"),
  },
  {
    path: "ticketo/event-details/:code",
    name: "event-details",
    props:true,
    component: () => import("./views/admin/ticketo-admin/event-details.vue"),
  },
  {
    path: "ticketo/active-tickets",
    name: "active-tickets",
    component: () => import("./views/admin/ticketo-admin/active-tickets.vue"),
  },
  {
    path: "ticketo/my-tickets",
    name: "my-tickets",
    component: () => import("./views/admin/ticketo-admin/my-tickets.vue"),
  },
  {
    path: "ticketo/my-scanned-tickets",
    name: "my-scanned-tickets",
    component: () => import("./views/admin/ticketo-admin/my-scanned-tickets.vue"),
  },
  {
    path: "ticketo/scanned-tickets",
    name: "scanned-tickets",
    component: () => import("./views/admin/ticketo-admin/scanned-tickets.vue"),
  },
  {
    path: "cashpower/contacts",
    name: "cashpower-contact",
    component: () => import("./views/admin/cashpower-contact"),
  },
  {
    path: "airtime/transactions",
    name: "airtime-transactions",
    component: () => import("./views/admin/airtime-transaction"),
  },
  {
    path: "cashpower/transactions",
    name: "cashpower-transactions",
    component: () => import("./views/admin/cashpower-transaction"),
  },
  {
    path: "send-money/transactions",
    name: "send-money-transactions",
    component: () => import("./views/admin/send-money-transactions"),
  },
  {
    path: "send-money/payout-details/:secret",
    name: "send-money-payout-details",
    component: () => import("./views/admin/payout-details"),
  },
  {
    path: "send-money/payout",
    name: "send-money-payout",
    component: () => import("./views/admin/payout"),
  },
  {
    path: "send-money/bank-payout",
    name: "send-money-bank-payout",
    component: () => import("./views/admin/bank-payout"),
  },

  {
    path: "send-money/payout-receipt/:secret",
    name: "send-money-payout-receipt",
    component: () => import("./views/admin/payout-receipt"),
  },

  {
    path: "send-money/contacts",
    name: "send-money-contacts",
    component: () => import("./views/admin/send-money-contacts"),
  },
  {
    path: "invoice/main",
    name: "invoice-main",
    component: () => import("./views/admin/invoice/invoicing"),
  },
  {
    path: "invoice/preview",
    name: "invoice-preview",
    component: () => import("./views/admin/invoice/invoice-previews"),
  },
  {
    path: "invoice/main/new",
    name: "invoice-new",
    component: () => import("./views/admin/invoice/new-invoice"),
  },
  {
    path: "invoice/customers",
    name: "invoice-customers",
    component: () => import("./views/admin/invoice/customers"),
  },
  {
    path: "invoice/items/list",
    name: "invoice-items",
    component: () => import("./views/admin/invoice/items"),
  },

  {
    path: "user-profile/profile/:code",
    name: "user-profile-code",
    component: () => import("./views/admin/user-profile/profile"),
  },
  {
    path: "user-profile/profile",
    name: "user-profile",
    component: () => import("./views/admin/user-profile/profile"),
  },
  {
    path: "user-details/details",
    name: "user-details",
    component: () => import("./views/admin/user-profile/user-details"),
  },
  {
    path: "airtime/contacts",
    name: "airtime-contact",
    component: () => import("./views/admin/airtime-contact"),
  },
  {
    path: "dashboard",
    name: "admin-dashboard",
    component: () => import("./views/admin/dashboard"),
  },
  {
    path: "kyc",
    name: "kyc",
    component: () => import("./views/customer/kyc"),
  },
  {
    path: "users",
    name: "admin-users",
    component: () => import("./views/admin/users"),
  },
  {
    path: "tenant-users",
    name: "admin-tenant-users",
    component: () => import("./views/admin/tenant-users"),
  },
  {
    path: "tenant-wallets",
    name: "admin-tenant-wallets",
    component: () => import("./views/admin/tenant-wallets"),
  },
  {
    path: "agencies",
    name: "admin-agencies",
    component: () => import("./views/admin/agencies"),
  },
  {
    path: "companies",
    name: "admin-companies",
    component: () => import("./views/admin/companies"),
  },
  {
    path: "sub-agents",
    name: "admin-sub-agents",
    component: () => import("./views/admin/sub-agents"),
  },
  {
    path: "branches",
    name: "admin-branches",
    component: () => import("./views/admin/branches"),
  },
  {
    path: "wallet-transfer",
    name: "admin-wallet-transfer",
    component: () => import("./views/admin/wallet-transfer"),
  },
  {
    path: "exchange-rates",
    name: "admin-exchange-rates",
    component: () => import("./views/admin/exchange-rates"),
  },
  {
    path: "advance/exchange-rates",
    name: "advance-admin-exchange-rates",
    component: () => import("./views/admin/advance-exchange-rates"),
  },
  {
    path: "api-keys",
    name: "aadmin-api-keys",
    component: () => import("./views/admin/api-keys"),
  },
  {
    path: "agency/:code",
    name: "admin-agency",
    component: () => import("./views/admin/agency"),
  },
/*   {
    path: "company/:code",
    name: "admin-company",
    component: () => import("./views/admin/company"),
  }, */
  {
    path: "circle/:code",
    name: "admin-circle",
    component: () => import("./views/admin/circle"),
  },
  {
    path: "activity-monitor",
    name: "activity-monitor",
    component: () => import("./views/admin/activity-monitor/transactions"),
  },
/*   {
    path: "activity-transaction-details",
    name: "activity-transaction-details",
    component: () => import("./views/admin/activity-monitor/transaction-details"),
  }, */
  {
    path: "messaging/push-notifications",
    name: "push-notifications",
    component: () => import("./views/admin/messaging/push-notifications"),
  },
  {
    path: "messaging/whatsapp",
    name: "messaging-whatsapp",
    component: () => import("./views/admin/messaging/whatsapp"),
  },
  {
    path: "active-activity-monitor",
    name: "active-activity-monitor",
    component: () => import("./views/admin/activity-monitor/active-transactions"),
  },
  {
    path: "device-messages",
    name: "device-messages",
    component: () => import("./views/admin/activity-monitor/device-messages"),
  },
  {
    path: "devices",
    name: "devices",
    component: () => import("./views/admin/activity-monitor/devices"),
  },
  {
    path: "configs",
    name: "configs",
    component: () => import("./views/admin/activity-monitor/configs"),
  },
  {
    path: "compliance",
    name: "compliance-index",
    component: () => import("./views/admin/compliance/users"),
  },
];

const reportRoutes = [
  {
    path: "report/payout-agency",
    name: "report-payout-agency",
    component: () => import("./views/admin/report/payout-agency.vue"),
  },
  {
    path: "report/tenant-transactions",
    name: "report-tenant-transactions",
    component: () => import("./views/admin/report/tenant-transactions.vue"),
  },
  {
    path: "report/user-signups",
    name: "report-user-signups",
    component: () => import("./views/admin/report/user-signups.vue"),
  },
  {
    path: "report/payment-logs",
    name: "report-payment-logs",
    component: () => import("./views/admin/report/payment-logs.vue"),
  },
  {
    path: "report/payout-branch",
    name: "report-payout-branch",
    component: () => import("./views/admin/report/payout-branch.vue"),
  },
  {
    path: "report/awaiting-dispatch",
    name: "report-awaiting-dispatch",
    component: () => import("./views/admin/report/awaiting-dispatch.vue"),
  },
  {
    path: "report/pending-bank-payment",
    name: "report-pending-bank-payment",
    component: () => import("./views/admin/report/pending-bank-payment.vue"),
  },
  {
    path: "report/pending-bill-payment",
    name: "report-pending-bill-payment",
    component: () => import("./views/admin/report/pending-bill-payment.vue"),
  },
  {
    path: "report/bank-payouts",
    name: "report-bank-payouts",
    component: () => import("./views/admin/report/bank-payout.vue"),
  },
  {
    path: "report/bill-payouts",
    name: "report-bill-payouts",
    component: () => import("./views/admin/report/bill-payout.vue"),
  },
  {
    path: "report/payout-cashier",
    name: "report-payout-cashier",
    component: () => import("./views/admin/report/payout-cashier.vue"),
  },
  {
    path: "report/payout-me",
    name: "report-payout-me",
    component: () => import("./views/admin/report/payout-me.vue"),
  },
];

const publicRoutes = [
  {
    path: "/playground/pay",
    meta: {
      requiresAuth: false,
    },
    name: "pay-playgroud",
    component: () => import("./views/public/pay-playground"),
  },
  {
    path: "/pay",
    meta: {
      requiresAuth: false,
    },
    name: "pay",
    component: () => import("./views/public/pay"),
  },
  {
    path: "/send-money",
    meta: {
      requiresAuth: false,
    },
    name: "send-Money",
    alias: ['money-transfer-gambia', 'send-money-to-gambia'],
    component: () => import("@tenant/views/public/send-money.vue"),
  },
  {
    path: "/buy-cash-power",
    meta: {
      requiresAuth: false,
    },
    name: "buy-cash-power",
    alias: [
      "buy-nawec-cashpower-online",
      "purchase-nawec-cashpower-internet",
      "online-nawec-cashpower-purchase",
      "get-nawec-cashpower-online",
      "buy-cashpower-nawec-online",
      "secure-nawec-cashpower-online",
      "instant-nawec-cashpower-online",
      "online-payment-nawec-cashpower",
      "easy-nawec-cashpower-online",
      "buy-nawec-power-online"
  ],
    component: () => import("./views/public/topup-cashpower-stepper"),
    
  },
  {
    path: "/confirm-cashpower-purchase",
    meta: { requiresAuth: false },
    name: "confirm-cashpower-purchase",
    component: () => import("./views/public/confirm-cashpower-purchase"),
  },
  {
    path: "/buy-airtime",
    meta: {
      requiresAuth: false,
    },
    name: "buy-airtime",
    alias: [
      "buy-mobile-credit/africell",
      "buy-qcell-credit/africell",
      "buy-comium-credit/africell",
      "buy-gamcel-credit/africell",
      "buy-africell-mobile-online",
      "buy-comium-mobile-online",
      "buy-gamcel-mobile-online",
      "buy-qcell-mobile-online",
    ],
    component: () => import("./views/public/topup-airtime-stepper"),
  },
  // {
  //   path: '/topup-airtime-old',
  //   meta: {
  //     requiresAuth: false
  //   },
  //   name: 'topup-airtime',
  //   component: () => import('./views/public/topup-airtime')
  // },
  {
    path: "/topup-airtime",
    meta: {
      requiresAuth: false,
    },
    name: "topup-airtime",
    component: () => import("./views/public/topup-airtime-stepper"),
  },
  {
    path:"/products/buy-giftcard",
    
      meta:{ requiresAuth: false},
      name:"buy-giftcard",
      component: ()=> import("./views/public/buy-giftcard-stepper")
    
  },
  {
    path: "/products/giftcards",
    meta: {
      requiresAuth: false,
    },
    name: "giftcards",
    component: () => import("./views/public/giftcard"),
  },

  {
    path: "/products/tickets/:categoryCode",
    meta: {
      requiresAuth: false,
    },
    name: "tickets-category-code",
    component: () => import("./views/public/ticketing"),
  },
  {
    path: "/products/tickets/sports",
    meta: {
      requiresAuth: false,
    },
    name: "tickets-sports",
    component: () => import("./views/public/ticketing"),
  },
  {
    path: "/products/tickets",
    meta: {
      requiresAuth: false,
    },
    name: "tickets-all-events",
    component: () => import("./views/public/ticketing"),
  },
  {
    path: "/products/tickets/concerts",
    meta: {
      requiresAuth: false,
    },
    name: "tickets-concerts",
    component: () => import("./views/public/ticketing"),
  },
  {
    path: "/products/tickets/transport",
    meta: {
      requiresAuth: false,
    },
    name: "tickets-transport",
    component: () => import("./views/public/ticketing"),
  },
  {
    path: "/products/tickets/most-popular",
    meta: {
      requiresAuth: false,
    },
    name: "most-popular",
    component: () => import("./views/public/ticketing"),
  },
  {
    path: "/products/tickets",
    meta: {
      requiresAuth: false,
    },
    name: "tickets",
    component: () => import("./views/public/ticketing"),
  },
  {
    path: "/products/nduga",
    meta: {
      requiresAuth: false,
    },
    name: "nduga-products",
    component: () => import("./views/public/nduga-product"),
  },
  {
    path: "/products/cart",
    name: "product-cart",
    component: () => import("./views/public/product-cart"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/products/events",
    meta: {
      requiresAuth: false,
    },
    name: "events",
    component: () => import("./views/public/event"),
  },
  {
    path: "/confirm-topup/:mobileNumber",
    meta: {
      requiresAuth: false,
    },
    name: "confirm-topup",
    component: () => import("./views/public/confirm-topup"),
  },
  {
    path: "/shop-online",
    meta: {
      requiresAuth: false,
    },
    name: "shop-online",
    component: () => import("./views/public/send-money"),
  },
  {
    path: "/pay-bills",
    meta: {
      requiresAuth: false,
    },
    name: "pay-bills",
    component: () => import("./views/public/send-money"),
  },
  {
    path: "/legal",
    meta: {
      requiresAuth: false,
    },
    name: "legal",
    component: () => import("./views/public/legal"),
  },

  {
    path: "/privacy-policy",
    meta: {
      requiresAuth: false,
    },
    name: "privacy-policy",
    component: () => import("@tenant/views/public/privacy-policy.vue"),
  },
  {
    path: "/aml",
    meta: {
      requiresAuth: false,
    },
    name: "anti-money-laundering",
    component: () => import("@tenant/views/public/aml-policy.vue"),
  },
  {
    path: "/anti-fraud-policy",
    meta: {
      requiresAuth: false,
    },
    name: "anti-fraud-policy",
    component: () => import("@tenant/views/public/anti-fraud-policy.vue"),
  },
  {
    path: "/terms-and-conditions",
    meta: {
      requiresAuth: false,
    },
    name: "terms-and-conditions",
    component: () => import("@tenant/views/public/terms-and-conditions.vue"),
  },
  {
    path: "/cookies-policy",
    meta: {
      requiresAuth: false,
    },
    name: "cookies-policy",
    component: () => import("@tenant/views/public/cookies-policy.vue"),
  },
  {
    path: "/contact-us",
    meta: {
      requiresAuth: false,
    },
    name: "contact-us",
    component: () => import("@tenant/views/public/contact-us.vue"),
  },
  {
    path: "/payout-locations",
    meta: {
      requiresAuth: false,
    },
    name: "payout-locations",
    component: () => import("@tenant/views/public/locate-us.vue"),
  },
  {
    name: "PublicPaymentSuccess",
    path: "/payment-success/:type/:code",
    component: () => import("./views/payment/payment-success"),
  },
  {
    name: "PublicPaymentDecline",
    path: "/payment-decline/:type/:code",
    component: () => import("./views/payment/payment-decline"),
  },
  
];


const iframeRoutes = [
  {
    path: "topup-cashpower",
    meta: {
      requiresAuth: false,
    },
    name: "iframe-topup-cashpower",
    component: () => import("./views/public/topup-cashpower-stepper"),
  },
  {
    path: "topup-airtime",
    meta: {
      requiresAuth: false,
    },
    name: "iframe-topup-airtime",
    component: () => import("./views/public/topup-airtime-stepper"),
  },
  {
    path: "topup",
    meta: {
      requiresAuth: false,
    },
    name: "top-up",
    component: () => import("./views/public/topup"),
  },
  {
    path: "kyc",
    name: "iframe-kyc",
    component: () => import("./views/customer/kyc"),
  },
  {
    path: "veriff",
    name: "iframe-veriff",
    component: () => import("./views/customer/veriff"),
  },
];

const appRoutes = [
  {
    path: "chat",
    meta: {
      requiresAuth: false,
    },
    name: "chat",
    component: () => import("./views/public/chat"),
  },
  {
    path: "topup-cashpower",
    meta: {
      requiresAuth: false,
    },
    name: "app-topup-cashpower",
    component: () => import("./views/public/topup-cashpower-stepper"),
  },
  {
    path: "topup-airtime",
    meta: {
      requiresAuth: false,
    },
    name: "app-topup-airtime",
    component: () => import("./views/public/topup-airtime-stepper"),
  },
  {
    path: "topup",
    meta: {
      requiresAuth: false,
    },
    name: "app-top-up",
    component: () => import("./views/public/topup"),
  },
  {
    path: "kyc",
    name: "app-kyc",
    component: () => import("./views/customer/kyc"),
  },
  {
    path: "legal",
    meta: {
      requiresAuth: false,
    },
    name: "app-legal",
    component: () => import("./views/public/legal"),
  },

  {
    path: "privacy-policy",
    meta: {
      requiresAuth: false,
    },
    name: "appprivacy-policy",
    component: () => import("@tenant/views/public/privacy-policy.vue"),
  },
  {
    path: "aml",
    meta: {
      requiresAuth: false,
    },
    name: "app-anti-money-laundering",
    component: () => import("@tenant/views/public/aml-policy.vue"),
  },
  {
    path: "aml-policy",
    meta: {
      requiresAuth: false,
    },
    name: "app-anti-money-laundering-policy",
    component: () => import("@tenant/views/public/aml-policy.vue"),
  },
  {
    path: "terms-and-conditions",
    meta: {
      requiresAuth: false,
    },
    name: "app-terms-and-conditions",
    component: () => import("@tenant/views/public/terms-and-conditions.vue"),
  },
  {
    path: "cookies-policy",
    meta: {
      requiresAuth: false,
    },
    name: "app-cookies-policy",
    component: () => import("@/views/public/cookies-policy.vue"),
  },
  {
    path: "contact-us",
    meta: {
      requiresAuth: false,
    },
    name: "app-contact-us",
    component: () => import("@tenant/views/public/contact-us.vue"),
  },
  {
    path: "profile",
    name: "app-profile",
    component: () => import("./views/admin/app-profile"),
  },
  {
    path: "send-money/payout",
    name: "app-send-money-payout",
    component: () => import("./views/public/payout"),
    
  },
  {
    path: "send-money/payout-details/:secret",
    name: "app-send-money-payout-details",
    component: () => import("./views/public/payout-details"),
  },
  {
    name: "AppPublicPaymentSuccess",
    path: "/payment-success/:code",
    component: () => import("./views/payment/payment-success"),
  },
  {
    name: "AppPublicPaymentDecline",
    path: "/payment-decline/:code",
    component: () => import("./views/payment/payment-decline"),
  },
];

const abbrRoutes = [
  {
    path: "v/complete",
    name: "abbr-veriff-complete",
    component: () => import("./views/customer/veriff-complete"),
  },
  {
    path: "v",
    name: "abbr-veriff",
    component: () => import("./views/customer/veriff"),
  },
  {
    path: "truelayer/callback/:secret/:referer",
    meta: {
      requiresAuth: false,
    },
    name: "truelayer-callback",
    component: () => import("./views/public/truelayer-callback"),
  },
  {
    path: "verify/:code",
    meta: {
      requiresAuth: false,
    },
    name: "verify",
    component: () => import("./views/public/verify"),
  },
  {
    path: ":secret",
    meta: {
      requiresAuth: false,
    },
    name: "payment-link",
    component: () => import("./views/public/payment-link"),
  },
  {
    name: "AbbrPublicPaymentSuccess",
    path: "/payment-success/:code",
    component: () => import("./views/payment/payment-success"),
  },
  {
    name: "AbbrPublicPaymentDecline",
    path: "/payment-decline/:code",
    component: () => import("./views/payment/payment-decline"),
  },
];

const identityRoutes = [
  {
    path: ":code",
    name: "identity-index",
    component: () => import("./views/identity/main"),
  },
  {
    path: ":stage/:code",
    name: "identity-stage",
    component: () => import("./views/identity/main"),
  },
];



let mainRoutes = [
  {
    path: "/pos",
    meta: {
      requiresAuth: true,
    },
    component: () => import("./layouts/pos"),
    children: [...posRoutes],
  },
  {
    path: "/identity",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./layouts/identity"),
    children: [...identityRoutes],
  },
  {
    path: "/",
    meta: {
      requiresAuth: false,
    },
    component: () => import("@/layouts/default.vue"),
    children: publicRoutes,
  },
  {
    path: "/iframe",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./layouts/iframe"),
    children: [...iframeRoutes],
  },
  {
    path: "/app",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./layouts/app"),
    children: [...appRoutes],
  },
  {
    path: "/p",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./layouts/abbr"),
    children: [...abbrRoutes],
  },
  {
    path: "/template",
    meta: {
      requiresAuth: false,
    },
    component: () => import("./layouts/template"),
  },

  {
    path: "/admin",
    meta: {
      requiresAuth: true,
    },
    component: () => import("./layouts/admin"),
    children: [...adminRoutes, ...reportRoutes],
  },
  {
    name: "POSTransactionsMain",
    path: "/transactions",
    component: () => import("./views/pos/transactions"),
  },
  {
    name: "HomeRedirect",
    path: "/",
    redirect: "/",
  },
  {
    name: "LogIn",
    meta: {
      requiresAuth: false,
    },
    path: "/login",
    component: () => import("./views/public/login"),
  },
  {
    name: "ResetPasswordSend",
    meta: {
      requiresAuth: false,
    },
    path: "/reset-password",
    component: () => import("./views/public/reset-password"),
  },
  {
    name: "ResetPassword",
    meta: {
      requiresAuth: false,
    },
    path: "/reset-password/:code",
    component: () => import("./views/public/reset-password"),
  },
  {
    name: "NewPassword",
    meta: {
      requiresAuth: false,
    },
    path: "/new-password",
    component: () => import("./views/public/new-password"),
  },
  {
    name: "Login-Test",
    path: "/login-test",
    component: () => import("./views/public/login-test"),
  },
  {
    name: "SignUp",
    path: "/signup",
    component: () => import("./views/public/signup"),
  },
  {
    name: "SignUpCompany",
    path: "/signup/:companyName/:accountType",
    component: () => import("./views/public/signup"),
  },
  {
    path: "*",
    redirect: "/",
  },
];


if(typeof defaultRoute.main != "undefined" && defaultRoute.main) {
  mainRoutes = mainRoutes.filter((item) => item.path !== "/");
  mainRoutes.push(defaultRoute);
} else {
  publicRoutes.unshift(defaultRoute);
}

const routes = [...mainRoutes];

const router = new Router({
  mode: "history",
  base: "/",
  routes,
  scrollBehavior() {
    const timeout = setTimeout(() => {
      const preloader = document.getElementById("preloader");
      if (preloader !== null || undefined) {
        preloader.remove();
      }
      clearTimeout(timeout);
    }, 500);
    return {
      x: 0,
      y: 0,
    };
  },
});

const checkLogin = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    // if (store.getters.can('read', to.name)) {
    next();
    return;
    // }
    // console.log('backToIndex');
    // router.push('/');
    // return;
  }

  const { path, query } = to;
  // console.log('performLogin');
  store.dispatch("performLogin", {
    path,
    query,
  });
};

router.beforeEach(checkLogin);

export default router;
