// import { AbilityBuilder } from "@casl/ability";
import { defineAbility } from '@casl/ability';
import { TENANT } from "@/config";

export default (user, wallet) => defineAbility((can) => {
  if (user == null || wallet == null) {
    return;
  }
  // const role = null;
  const walletType = wallet.type;
  
//   const { rules, can } = new AbilityBuilder();

  let DisplayFeatures = ["SHOWHEADER"];

  let CustomerFeatures = ["TOPUP", "SWITCHACCOUNTS", "BUYCASHPOWER", "BUYAIRTIME", "PAYREVENUE", "PAYBILLS", "CREATEINVOICE", "SENDMONEY", "UPDATEPROFILE", "TOPUP", "CREATEWALLETS",];

  
  if(TENANT === 'ticketo') {
    CustomerFeatures = [];
    DisplayFeatures = [];
  }

  const MerchantFeatures = ["BRANCHREPORT", "SWITCHACCOUNTS", "ADDAGENT", "TRANSFER", "AGENCYREPORTS"];

  const SubMerchantFeatures = [ "SWITCHACCOUNTS", "COLLECTREVENUE", "COLLECTBILLS", "PAYOUT", "PAYIN", "LOCALTRANSFER", "TRANSFERTOAGENT"];
  
  const TenantFeatures = ["LOGS", "MANAGEAGENCIES", "MANAGECURRENCIES", "MANAGEUSERS", "MANAGEEXCHANGERATES", "MANAGEBRANCHES", "TENANTREPORTS", "UPDATERECIPIENT", "UPDATECSSTATUS", "TRANSFERPAYOUT", "SEECUSTOMERTAGS"];

  const TenantOwnerFeatures = ["LOCKACCOUNT", "MANAGEMESSAGING", "UPDATECSSTATUS", "TRANSFERPAYOUT", "SENDNOTIFICATION", "SEECUSTOMERTAGS"];

  const ADMINSUPPORTFEATURES = ["TENANTREPORTS", "UPDATECSSTATUS", "TRANSFERPAYOUT", "SEECUSTOMERTAGS"];

//const INNOVARXFEATURE = ["INNOVARX"]


const TICKETOADMIN = ["TICKETOADMIN"];
const TICKETOCUSTOMER = ["TICKETOCUSTOMER"];

const APIFeatures = ["CANCREATEAPIKEYS"];

  // const BUYCASHPOWERFEATURE = ["BUYCASHPOWER"];
  // const BUYAIRTIMEFEATURE = ["BUYAIRTIME"];

  const SELLCASHPOWERFEATURE = ["SELLCASHPOWER"];
  const SELLAIRTIMEFEATURE = ["SELLAIRTIME"];
  const CREATEWALLETFEATURE = ["CREATEWALLET"];
  const WALLETTRANSFERFEATURE = ["WALLETTRANSFER"];

  const MANAGEAGENCYFEATURE = ["MANAGEAGENCY"];

  const MONITORINGFEATURE = ["MONITORING"];

  const COMPLIANCEFEATURE = ["COMPLIANCE"];

  const PPSREPORTS = ["PPSREPORTS"];

  

  //   const dashboard = [
  //     'DashboardRoot',
  //     'dashboard',
  //   ];

  //   const email = [
  //     'EmailView',
  //     'EmailListView',
  //     'EmailSentView',
  //     'EmailViewFolder',
  //   ];

  //   const emailConfig = [
  //     'RulesView',
  //     'TemplatesView',
  //     'UpdateTemplateView',
  //     'UpdateRuleView',
  //     'EmailAccountView',
  //     'UpdateEmailAccountView',
  //     'updateEmailAccount',
  //     'deleteEmail',
  //     'updateEmailFolder',
  //   ];

  //   const ticketsRead = [
  //     'ticketMenu',
  //     'ticketboard',
  //     'editTicket',
  //     'ticketactivity',
  //     'TicketActivityModel',
  //   ];

  //   const ticketsActivityList = [
  //     'ticketactivityView',
  //   ];

  //   const ticketStatuses = [
  //     'TicketTableListModel',
  //   ];

  //   const ticketsDelete = [
  //     'deleteTicket',
  //   ];

  //   const ticketsWrite = [
  //     'editTicket',
  //     'createTicket',
  //     'boardLaneAdd',
  //   ];

  //   const ticketSettingWrite = [
  //     'settingTicketType',
  //     'createTicketType',
  //     'deleteTicketType',
  //     'editTicketType',
  //     'TicketStatuses',
  //     'TicketPriorities',
  //     'TicketSLA',
  //     'createTicketSla',
  //     'editTicketSla',
  //     'deleteTicketSla',
  //   ];

  //   const ticketSettingRead = [
  //     'UpdateSettingTicketType',
  //     'settingTicketType',
  //     'TicketStatuses',
  //     'TicketPriorities',
  //     'TicketSLA',
  //     'UpdateSettingTicketSLA',
  //   ];

  //   const formsRead = [
  //     'FormRenderer',
  //   ];

  //   const formsWrite = [
  //   ];

  //   const userManagementRead = [
  //     'AccountRoot',
  //     'users',
  //     'browseUser',
  //     'editUser',
  //     'userRole',
  //     'editUserRole',
  //     'businessunit',
  //     'editBusinessunit',
  //     'WorkingHours',
  //   ];

  //   const userManagementWrite = [
  //     'editUser',
  //     'createUser',
  //     'userRole',
  //     'editUserRole',
  //     'deleteUser',
  //     'createBusinessunit',
  //     'editBusinessunit',
  //     'deleteBuninessUnit',
  //     'editWorkingHours',
  //   ];

  //   const menu = [
  //     'Layout',
  //   ];

  //   const workflow = [
  //     'workflow',
  //   ];

  //   const calendar = [
  //     'CalendarView',
  //   ];

  //   const settings = [
  //     'settingsMenu',
  //   ];
  const customerTypes = ["SERVICEGMD"];
  const agentTypes = ["PAYAGENT", "NAWECAGENT", "AIRTIMEAGENT"];
  const subAgentTypes = ["PAYSUBAGENT"];
  const tenantType = ["TENANT"];
  const adminSupportTypes = ["ADMINSUPPORT", "TENANT"];
  if(user.tenant === 'taybullpay' && user.can_create_api_keys) {
    can("read", APIFeatures);
  }
  
  // console.log('walletType', walletType);
  if (customerTypes.includes(walletType)) {
    can("read", CustomerFeatures);
    can("read", DisplayFeatures);
    //can("read", WALLETTRANSFERFEATURE);
  } else if (agentTypes.includes(walletType)) {
    can("read", MerchantFeatures);
    can("read", SubMerchantFeatures);
    can("read", DisplayFeatures);
    

    if(user.is_company_owner) {
      can("read", MANAGEAGENCYFEATURE);
    }


    if(user.tenant === 'taybullpay') {
      can("read", SELLCASHPOWERFEATURE);
      can("read", SELLAIRTIMEFEATURE);
      
      //can("read", WALLETTRANSFERFEATURE);
      //can("read", CREATEWALLETFEATURE);

 
    }
  } else if (subAgentTypes.includes(walletType)) {
    can("read", SubMerchantFeatures);
    if(user.tenant === 'taybullpay') {
      can("read", SELLCASHPOWERFEATURE);
      can("read", SELLAIRTIMEFEATURE);
      //can("read", WALLETTRANSFERFEATURE);
      //can("read", CREATEWALLETFEATURE);
    }
  } else if (tenantType.includes(walletType)) {
    
    // can("read", SubMerchantFeatures);
    // can("read", CustomerFeatures);
    // can("read", MerchantFeatures);
    // alert("tenant");
    can("read", TenantFeatures);
    if(user.tenant === 'taybullpay') {
      can("read", CREATEWALLETFEATURE);
      can("read", WALLETTRANSFERFEATURE);
      can("read", MONITORINGFEATURE);
      can("read", COMPLIANCEFEATURE);

      // COMPLIANCEFEATURE
      // can("read", SELLCASHPOWERFEATURE);
      // can("read", SELLAIRTIMEFEATURE);
    }
    if(user.tenant === 'ticketo') {
      can("read", TICKETOADMIN);
    }
    if(user.is_tenant_owner) {
      can("read", TenantOwnerFeatures);
      can("read", PPSREPORTS);
    }
  } 
  if((user.is_tenant_owner || adminSupportTypes.includes(walletType))) {
    can("read", ADMINSUPPORTFEATURES);
    can("read", COMPLIANCEFEATURE);
  }
  if(user.tenant === 'taybullpay' && adminSupportTypes.includes(walletType)) {
    can("read", MONITORINGFEATURE);
    can("read", COMPLIANCEFEATURE);
  }

  if(user.tenant === 'innovarx' && tenantType.includes(walletType)){
    can("read", CREATEWALLETFEATURE);

  }
  if(user.tenant === 'ticketo' && tenantType.includes(walletType)){
    can("read", TICKETOADMIN);

  }
  if(customerTypes.includes(walletType)){
    can("read", TICKETOCUSTOMER)
  }
  
});
