import ApiService from "./api.service";

const ContactService = {
    addContact(type, payload) {
        // console.log('namespace', payload)
        return ApiService.post("/contact/" + type, payload);
        // return ApiService.post("/contact/" + namespace + "/" + type, payload);
    },
    getContacts(type, namespace, userCode) {
        let path = type;
        if (typeof namespace != 'undefined' && namespace) {
            path = namespace + "/" + type;
            // return ApiService.query("/contact/" + namespace + "/" + type);
        } else {
            // return ApiService.query("/contact/" + type);
        }
        if(typeof userCode != 'undefined' && userCode) {
            return ApiService.query("/contact/user/" + userCode + '/' + path);
        } else {
            return ApiService.query("/contact/" + path);
        }
    }
}

export default ContactService;