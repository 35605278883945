import ApiService from "./api.service";

const ReportService = {
  getReport(reportName, p) {
    const params = p ? p : {};
    params.reportName = reportName;
    return ApiService.query("/wallet/report", { params });
  },
  download(reportName, p) {
    const params = p ? p : {};
    params.reportName = reportName;
    return ApiService.getDownload("/wallet/download", params );
  },
};
export default ReportService;
